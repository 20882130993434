import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { API, API_ROUTER } from "../../../api";
import styled from "styled-components";
import { StyledRow } from "./style";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import getGameCards from "../const";
import {
  TeamsRow,
  GamesRow,
  StatisticsRow,
  TournamentsRow,
} from "../../../components/team-bricks";
import {
  InnerBox,
  ProgressCircle,
  Button,
  ContentBox,
} from "../../../components/UI";
import Row from "../../../components/profile-bricks/Row";
import { FormattedMessage, injectIntl } from "react-intl";

export default function Bottom({ team, history, teamId, isCreator }) {
  const [teamGames, setTeamGames] = useState([]);
  const [checkTeamId, setCheckTeamId] = useState([]);
  const [teamProgression, setTeamProgression] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const languagesList = useSelector((state) => state.languagesList);
  // получить игры команды
  useEffect(() => {
    API.request({
      ...API_ROUTER.teams.getTeamGames,
      pathKeys: {
        teamId: teamId,
      },
    })
      .then((res) => {
        setTeamGames(res.games);
        setRequestSuccess(true);
      })
      .catch((err) => console.log(err));
  }, []);
  // получить мои команды
  useEffect(() => {
    API.request({
      ...API_ROUTER.teams?.getMyTeams,
    })
      .then((res) => {
        setCheckTeamId(res.teams);
      })
      .catch((err) => console.log(err));
  }, []);

  // function getGameCards(history, teamId, teamGames) {
  //   let checkid = [];
  //   checkTeamId.map((el) => checkid.push(el.id));
  //   console.log(checkid);
  //   let answer = checkid.includes(teamId);
  //   let gameCard;
  //   if (answer)
  //     return (gameCard = [
  //       ...teamGames,
  //       {
  //         id: uuidv4(),
  //         add: true,
  //         title: <FormattedMessage id="settings.games.addGame" />,
  //         action: () => {
  //           history.push(`/teams/team/${teamId}/roster/discipline`);
  //         },
  //         img: "",
  //       },
  //     ]);

  //   if (!answer) {
  //     return (gameCard = [...teamGames]);
  //   }
  //   return gameCard;
  // }

  function getLanguages() {
    return team?.languages?.map((el) =>
      languagesList.find((item) => item?.value === el)
    );
  }

  function TeamLanguages() {
    const languages = [getLanguages(team?.languages)].flat();
    return (
      <>
        <StyledRow>
          <section>
            <h1>
              <FormattedMessage id="id.about" />
            </h1>
          </section>
        </StyledRow>
        <div className="info__team-team">
          <div className="info__team-team-item">
            {team?.languages?.length > 0 ? (
              <>
                <p>
                  <FormattedMessage id="global.forms.labels.language" />
                </p>
                {languages.map((el, i) => (
                  <h1 key={i}>{el?.label}</h1>
                ))}
              </>
            ) : (
              <p>
                <FormattedMessage id="global.forms.placeholders.noData" />
              </p>
            )}
          </div>
        </div>
      </>
    );
  }
  return (
    <div>
      <ContentBox className={classNames("contentBoxColor")}>
        <StyledTeamInfo>
          <TeamLanguages />
        </StyledTeamInfo>
      </ContentBox>

      <ContentBox className={classNames("contentBoxColor")}>
        <Row
          teamProgression={requestSuccess}
          title={<FormattedMessage id="id.teamMember" />}
          history={history}
          teamId={teamId}
          cards={getRosterCards(team)}
        />
      </ContentBox>

      <ContentBox className={classNames("contentBoxColor")}>
        <Row
          teamProgression={requestSuccess}
          title={<FormattedMessage id="id.tournaments" />}
          cards={getTournamentCards(team)}
        />
      </ContentBox>

      <ContentBox className={classNames("contentBoxColor")}>
        <GamesRow
          title={
            isCreator ? (
              <FormattedMessage id="id.myGames" />
            ) : (
              <FormattedMessage id="id.games" />
            )
          }
          cards={getGameCards(teamGames, history, isCreator, teamId)}
          requestSuccess={requestSuccess}
        />
        {/* <Row
          teamProgression={requestSuccess}
          title={<FormattedMessage id="id.games" />}
          cards={getGameCards(history, teamId, teamGames)}
        /> */}
      </ContentBox>
    </div>
  );
}

function getTournamentCards(user) {
  const tournamentCard = [
    {
      id: uuidv4(),
      add: false,
      title: <FormattedMessage id="id.tournaments.finished" />,
      img: "",
      icon: "rank",
      isComing: true,
    },
    {
      id: uuidv4(),
      add: false,
      title: <FormattedMessage id="id.tournaments.inProgress" />,
      img: "",
      icon: "timer",
      isComing: true,
    },
    {
      id: uuidv4(),
      add: false,
      title: <FormattedMessage id="id.tournaments.comingSoon" />,
      img: "",
      icon: "clock",
      isComing: true,
    },
    {
      id: uuidv4(),
      add: true,
      title: <FormattedMessage id="id.tournaments.addTo" />,
      img: "",
      isComing: true,
    },
  ];

  return tournamentCard;
}

function getRosterCards(user) {
  const statisticCard1 = [
    {
      id: uuidv4(),
      add: false,
      title: "Member list",
      img: "",
      icon: "user-group",
    },
    {
      id: uuidv4(),
      add: false,
      title: <FormattedMessage id="id.statistics.all" />,
      img: "",
      icon: "graphic",
      isComing: true,
    },
  ];

  return statisticCard1;
}
const StyledTeamInfo = styled.div`
  .info__team {
    margin: 0 0 10px 20px;
    font-size: 22px;
    font-weight: bold;
  }
  .info__team-team {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    vertical-align: baseline;
    flex-wrap: wrap;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.05);
    margin-top: 10px;
  }
  .info__team-team-item {
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    align-items: center;
  }

  h1 {
    font-size: 17px;
    padding: 0 10px 0 10px;
  }
`;
