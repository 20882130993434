import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { API, API_ROUTER } from "../../../../api";

import { Preloader } from "../../../../components/UI";

import styles from "./styles.module.scss";
import { MATCH_STATUS_ENDED, MATCH_STATUS_LIVE } from "../../constants";

export const RegisterMatch = ({ match, updateMemberData }) => {
  const [error, setError] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [isCheckIn, setIsCheckIn] = useState(match?.isCheckedIn);

  const checkInMatch = () => {
    setIsLoad(true);
    API.request({
      ...API_ROUTER.tournaments.checkIdMatch,
      data: {
        matchId: match?.matchId,
      },
    })
      .then((res) => {
        setIsCheckIn(true);
        updateMemberData();
      })
      .catch((err) => {
        setError(err?.data?.message);
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const checkOutMatch = () => {
    API.request({
      ...API_ROUTER.tournaments.checkOutMatch,
      data: {
        matchId: match.matchId,
      },
    })
      .then((res) => {
        setIsCheckIn(false);
        updateMemberData();
      })
      .catch((err) => setError(err?.data?.message))
      .finally(() => {
        setIsLoad(false);
      });
  };

  return (
    <div className={classNames("matchRegisterBg", styles.checkInContainer)}>
      {isLoad ? (
        <Preloader />
      ) : (
        <>
          {(!isCheckIn ||
            (!match?.isCheckedIn &&
              match?.status !== MATCH_STATUS_ENDED &&
              match?.status !== MATCH_STATUS_LIVE)) && (
            <button className={styles.button} onClick={checkInMatch}>
              <FormattedMessage id="match.ticker.checkIn" />
            </button>
          )}
          {(isCheckIn ||
            (!!match?.isCheckedIn &&
              match?.status !== MATCH_STATUS_ENDED &&
              match?.status !== MATCH_STATUS_LIVE)) && (
            <button className={styles.button} onClick={checkOutMatch}>
              <FormattedMessage id="match.ticker.checkOut" />
            </button>
          )}
          {error && (
            <div className={styles.wrapper}>
              <div className={styles.error}>{error}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RegisterMatch;
