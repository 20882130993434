import { API, API_ROUTER } from "../../api";

const getPublicUserProfile = (userUrl) => {
  const params = {
    // ...API_ROUTER.user.getPublicUserInfo,
    ...API_ROUTER.user.getPublicUserInfoURL,
    pathKeys: {
      // userId,
      userUrl,
    },
  };
  return API.request(params);
};

export default getPublicUserProfile;
