import React, { useState } from "react";

import TournamentModalInformation from "../modal";

import placeHolder from "../../../../assets/fake-team.png";

import styles from "./styles.module.scss";

const imageWithPlaceHolder = (logo) => {
  return (
    <img
      className={styles.styledCard__image}
      src={logo ? logo : placeHolder}
      alt="img"
    />
  );
};
const dumb = () => {
  console.log("there is no action on this card");
};
export default function Card({
  add = false,
  team,
  teamRoster,
  user,
  title,
  isComing = false,
  logo,
  action = dumb,
  name,
  tournamentId,
  tournamentMemberId,
}) {
  const [openModal, setOpenModal] = useState(!true);

  const NameOfCard = (value) => {
    return <p>{value.length > 15 ? value.slice(0, 15) + "..." : value}</p>;
  };
  return (
    <div className={styles.styledCard} key={tournamentId}>
      <section
        className={styles.styledCard__header}
        onClick={() => {
          tournamentId && setOpenModal(!false);
          !isComing ? action() : dumb();
        }}
      >
        {/* if add place PLUS else place image */}

        {add ? (
          <i className="icon icon-plus" style={{ background: "var(--icon)" }} />
        ) : (
          imageWithPlaceHolder(logo)
        )}
      </section>
      <section className={styles.styledCard__footer}>
        {!!name && NameOfCard(name ? name : "")}

        {!!title && NameOfCard(title)}
      </section>
      <TournamentModalInformation
        showQrModal={openModal}
        setOpenModal={setOpenModal}
        team={team}
        teamRoster={teamRoster}
        user={user}
        name={name}
        tournamentId={tournamentId}
        tournamentMemberId={tournamentMemberId}
      />
    </div>
  );
}
