import React, {useState, useEffect, useRef} from "react";
import {FormattedMessage} from "react-intl";

import styles from './styles.module.scss';
import arrowDown from "../../../assets/matchDiscussion/arrow-down.svg";


export interface CustomSelectProps {
    label?: string;
    onChange: (val: string | number) => void;
    options: {value?: string | number, id: string | number}[];
    defaultValue?: any;
    className?: any;
}

export const CustomSelect = ({ label = "match.ticker.customSelectDefault", options, onChange, defaultValue, className }: CustomSelectProps) => {
    const myRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const onChangeValue = (val: string | number) => {
        onChange(val);
        setIsOpen(false);
    }

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
        function handleClick(e: any) {
            if(myRef && myRef.current){
                const ref: any = myRef.current
                if(!ref.contains(e.target)){
                    setIsOpen(false);
                }
            }
        }
    }, []);


    return (
        <div className={`${styles.wrapper} ${className}`} ref={myRef as any}>
            <div className={styles.valueWrapper} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.value}>{defaultValue ? defaultValue?.value : <FormattedMessage id={label} />}</div>
                <img className={styles.icon} src={arrowDown} alt="down" />
            </div>

            {!!isOpen && (
                <div className={styles.selectOptionsWrapper}>
                    {options?.map((el, index) => (
                        <div
                            className={`${styles.item} ${el.id === defaultValue?.id ? styles.active : null}`}
                            key={`${index}-${el.value}`}
                            onClick={() => onChangeValue(el.id)}
                        >
                            {el.value}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
};

export default CustomSelect;
