import React from "react";
import styled from "styled-components";

//
import Bottom from "./sections/bottom/Bottom";
import Top from "./sections/top/Top";

function Dashboard({
  user = false,
  props,
  setShowTeamModal,
  isCreator,
  id,
  ...rest
}) {
  return (
    <StyledDashboard>
      <Top
        user={user}
        isEditMode={false}
        {...props}
        isCreator={isCreator}
        id={id}
        setShowTeamModal={setShowTeamModal}
      />
      <Bottom {...rest} user={user} isCreator={isCreator} id={id} />
    </StyledDashboard>
  );
}
export default Dashboard;
const StyledDashboard = styled.div`
  /* padding-bottom: 70px; */
`;
