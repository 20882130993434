export const SCORE_OPTIONS = [
    {
        id: 1,
        options: {
            1: [0],
            0: [1]
        }
    },
    {
        id: 2,
        options: {
            0: [2],
            1: [1],
            2: [0]
        }
    },
    {
        id: 3,
        options: {
            0: [2],
            1: [2],
            2: [0, 1]
        }
    },
    {
        id: 5,
        options: {
            0: [3],
            1: [3],
            2: [3],
            3: [0, 1, 2]
        }
    },
    {
        id: 7,
        options: {
            0: [4],
            1: [4],
            2: [4],
            3: [4],
            4: [0, 1, 2, 3]
        }
    },
];
export const NUMBER_TEAM_ONE = 1;
export const NUMBER_TEAM_TWO = 2;
