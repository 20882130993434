export const ifNeedRedirect = () => {
  let route;
  if (localStorage.tournamentId) {
    route = `/battleCup/${localStorage.tournamentId}/information`;
    localStorage.removeItem("communityId");
    localStorage.removeItem("eventId");
    localStorage.removeItem("tournamentId");
    localStorage.removeItem("profileUrl");
  }
  if (localStorage.eventId) {
    route = `/eventPage/${localStorage.eventId}`;
    localStorage.removeItem("communityId");
    localStorage.removeItem("eventId");
    localStorage.removeItem("tournamentId");
    localStorage.removeItem("profileUrl");
  }
  if (localStorage.communityId) {
    route = `/community/${localStorage.communityId}/events`;
    localStorage.removeItem("communityId");
    localStorage.removeItem("eventId");
    localStorage.removeItem("tournamentId");
    localStorage.removeItem("profileUrl");
  }
  if (localStorage.profileUrl) {
    route = `/id/${localStorage.profileUrl}`;
    localStorage.removeItem("communityId");
    localStorage.removeItem("eventId");
    localStorage.removeItem("tournamentId");
    localStorage.removeItem("profileUrl");
  }

  return route;
};
