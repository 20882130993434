import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import styles from "./styles.module.scss";
import { MENU_TABS, MENU_TABS_BANG } from "../../constants";
import { DEFAULT_ACTIVE_TAB_ID } from "./constants";

export const NavigationTabs = ({ onChangeActiveTab, style }) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB_ID);
  //TODO BIGBANG add TABS bc need  tournament tab and NEW tab GAMES
  const TABS = style === "bigbang" ? MENU_TABS_BANG : MENU_TABS;

  const changeActiveTab = (id, route) => {
    history.push(route);
    setActiveTab(id);
    onChangeActiveTab(id);
  };

  const setActiveTabWhenChangingRoute = () => {
    const active = TABS?.find((el) => el.route === history?.location?.pathname);

    if (active?.id !== activeTab || active?.id) {
      setActiveTab(active?.id);
      onChangeActiveTab(active?.id);
    }
  };

  useEffect(() => {
    setActiveTabWhenChangingRoute();
  }, [history?.location?.pathname]);

  return (
    <div className={styles.wrapper}>
      {TABS?.map((el) => (
        <div
          key={el.id}
          onClick={() => changeActiveTab(el.id, el.route)}
          className={classNames(styles.tab, {
            [styles.active]: el.id === activeTab,
          })}
        >
          <FormattedMessage id={el.title} />
        </div>
      ))}
    </div>
  );
};

export default NavigationTabs;
