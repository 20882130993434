import React from "react";
import styled, { css } from "styled-components";

export default function Button({ ...props }) {
  return <StyledButton {...props} />;
}

const StyledButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  font-style: normal;
  letter-spacing: 0.03em;
  line-height: normal;
  padding: 16px 45px;
  white-space: nowrap;
  width: 100%;
  height: 60px;

  ${({ active }) =>
    active &&
    css`
      background: #ffa900;
      color: #fff;
    `}
  ${({ inActive }) =>
    inActive &&
    css`
      background: #fff;
      color: #3d3177;
    `}
    ${({ size }) =>
    size === "sm" &&
    css`
      font-size: 10px;
      padding: 10px 24px;
      border-radius: 12px;
    `}
    ${({ color }) =>
    color === "yellow" &&
    css`
      background-color: #ce8926;
      color: var(--dark);
    `};
`;
