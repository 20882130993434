import { useRef, useEffect, useState } from "react";
import { ContentBox, Container } from "../../components/UI";
import styles from "./styles.module.scss";
import pageNotFoundImg from "../../assets/404.png";
import PlacesAutocomplete from "react-places-autocomplete";

function PageNotFound() {
  const [address, setAddress] = useState("");
  const [placeId, setPlaceId] = useState("");
  console.log(placeId);
  console.log(address);
  const handleChange = (address, placeId) => {
    setAddress(address);
    setPlaceId(placeId);
  };

  const handleSelect = (address, placeId) => {
    setAddress(address);
    setPlaceId(placeId);
  };

  const maps = () => {
    let options = {
      types: ["(cities)"],
      // types: ["country"],
      fields: ["place_id"],
      language: "EN",
    };
    return (
      <div>
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
          searchOptions={options}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                style={{ color: "black" }}
                {...getInputProps({
                  placeholder: "Enter Address...",
                })}
              />
              <div>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const style = suggestion.active
                    ? {
                        backgroundColor: "#a83232",
                        cursor: "pointer",
                        color: "black",
                      }
                    : {
                        backgroundColor: "#ffa901",
                        cursor: "pointer",
                        color: "black",
                      };

                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                    >
                      {suggestion.description}
                      {suggestion.placeId}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  };

  return (
    <Container>
      <ContentBox>
        <div className={styles.wentWrong}>
          <img className={styles.img} src={pageNotFoundImg} />
          <p> Something went wrong</p>
        </div>
      </ContentBox>
      <ContentBox>
        <div className={styles.content}>
          Seems like you’ve typed wrong address – if so, please check the URL
          you’ve entered and enter the correct one. Try again.
        </div>
        <div className={styles.content}>
          If this is not the case – that it seems that you’ve found a bug in the
          system. We are extremely upset, that this has happened. We take
          serious every situation like this, hence please help us to improve the
          system and report the bug. You can do it here:
        </div>
        <div className={styles.content}>
          <a
            href="https://about.passport.gg/gamersupport"
            target="_blank"
            className={styles.supportUrl}
          >
            https://about.passport.gg/gamersupport
          </a>
        </div>
        <div className={styles.content}>
          We will work on fixing the issue as soon as possible.
        </div>{" "}
        <div className={styles.content}>
          Esportly yours, <br /> Passport.GG team
        </div>
      </ContentBox>
      {/* <div>{maps()}</div> */}
    </Container>
  );
}
export default PageNotFound;
