import { API, API_ROUTER } from "../../api";

const getteamMembers = (teamId, gameId) => {
  const params = {
    ...API_ROUTER.teams.getMembersGame,
    pathKeys: {
      teamId,
      gameId,
    },
  };
  return API.request(params);
};

export default getteamMembers;
