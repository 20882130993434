import React, { useState } from "react";

import { NavigationTabs } from "./components";

import { MENU_TABS, MENU_TABS_BANG } from "./constants";

const Calendar = ({ style }) => {
  const [activeTab, setActiveTab] = useState();
  console.log(style);
  return (
    <div>
      <div>
        <NavigationTabs
          onChangeActiveTab={(id) => setActiveTab(id)}
          style={style}
        />
        {style === "bigbang"
          ? MENU_TABS_BANG?.map(
              (tab) =>
                tab?.id === activeTab && (
                  <div key={tab?.id}>{tab.components()}</div>
                )
            )
          : MENU_TABS?.map(
              (tab) =>
                tab?.id === activeTab && (
                  <div key={tab?.id}>{tab.components()}</div>
                )
            )}
      </div>
    </div>
  );
};

export default Calendar;
