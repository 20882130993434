import React, { FunctionComponent } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { showAuthModal } from "../../../redux/actions/auth";
import { RootState } from "../../../redux/store";
import store from "../../../redux/store";

import { ROUTER } from "../../../config";
import Lock from "../../lock";
import { StyledNav, Dropdown } from "./style";
import { isAuthenticated } from "../../../helpers";

const LoginButton = () => {
  return (
    <li>
      <a
        href={ROUTER.login}
        // onClick={(e) => {
        //   e.preventDefault();
        //   store.dispatch(showAuthModal());
        // }}
      >
        <FormattedMessage id="navigation.login" />
      </a>
    </li>
  );
};

interface INavLink {
  label: JSX.Element | string;
  to: string;
  activeOnlyWhenExact?: boolean;
  locked?: boolean;
}

const NavLink: FunctionComponent<INavLink> = ({
  label,
  to,
  activeOnlyWhenExact,
  locked,
}) => {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <li className={`${match ? "active" : ""} ${locked ? "locked" : ""}`}>
      <Link to={to}>
        {label}
        {locked && <Lock />}
      </Link>
    </li>
  );
};

interface IHeaderNav {
  navStatus?: boolean;
  // TODO BIGBANG add style domen
  style?: string;
}

const HeaderNav: FunctionComponent<IHeaderNav> = ({ navStatus, style }) => {
  const isUserOnline = useSelector<RootState>((state) => state.userOnline);

  return (
    <>
      <StyledNav
        className={classNames("headerNavLinksColor")}
        navStatus={navStatus}
      >
        {/* TODO BIGBANG add style domen */}
        {style === "bigbang" ? (
          <NavLink to="/calendar/tournaments" label="Register" />
        ) : (
          <NavLink to="/calendar/tournaments" label="Calendar" />
        )}
        {/* TODO BIGBANG add style domen */}
        {style !== "bigbang" && (
          <NavLink
            to="/rating"
            label={<FormattedMessage id="ratings.rating" />}
          />
        )}
        <NavLink
          to="/watch"
          label={<FormattedMessage id="id.performance.dota.matches" />}
        />

        <NavLink to="/followed/tournaments" label={"Followed"} />
        <li>
          <a
            href="https://about.passport.gg/gamersupport"
            target={"_blank"}
            style={{ textDecoration: "none" }}
          >
            <FormattedMessage id="footer.support" />
          </a>
        </li>

        {/* <Dropdown.Wrapper>
        <Link to="/calendar/tournaments">
          <FormattedMessage id="footer.calendar" />
        </Link>
        <Dropdown.Holder>
          <Dropdown.List>
            <NavLink
              to="/calendar/events"
              label={<FormattedMessage id="calendar.events" />}
            />
            <NavLink
              to="/calendar/tournaments"
              label={<FormattedMessage id="calendar.tournaments" />}
            />
            <NavLink
              to="/calendar/community"
              label={<FormattedMessage id="navigation.community" />}
            />
          </Dropdown.List>
        </Dropdown.Holder>
      </Dropdown.Wrapper> */}
        {!isUserOnline && <LoginButton />}
      </StyledNav>
    </>
  );
};

export default HeaderNav;
