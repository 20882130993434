import React, { useState, useEffect } from "react";
import s from "./inviteTeam.module.scss";
import { API, API_ROUTER } from "../../api";
import defaultAvatar from "../../assets/avatars/avatar.jpg";
import MemberItem from "../../components/memberItem";
import { FormattedMessage } from "react-intl";

const InviteTeam = () => {
  const teamId = window.location.href
    .split("/teams/team/")[1]
    .split("/invite")[0];

  const initialMembers = [{ id: 1, nickname: "Loading...", avatar: null }];
  const [members, setMembers] = useState(initialMembers);
  const [inputText, setInputText] = useState("");
  const [err, setErrt] = useState(false);
  const [errText, setTextErr] = useState("");
  const [team, setTeam] = useState(null);
  const [answerFromServer, setAnswerFromServer] = useState(null);

  const getMembers = (id) => {
    API.request({
      ...API_ROUTER.teams.getMembers,
      pathKeys: {
        teamId: id,
      },
    })
      .then((res) => {
        setMembers(res.members);
      })
      .catch((err) => console.log(err));
  };
  const getTeam = (id) => {
    API.request({
      ...API_ROUTER.teams.getTeamDetails,
      pathKeys: {
        teamId: id,
      },
    })
      .then((data) => {
        setTeam(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getMembers(teamId);
    getTeam(teamId);
  }, []);
  // public/v1/team/01G8DH6YAY8P3Z7ABB05ZRZKE0/members
  // /api/v1/team/01G8DH6YAY8P3Z7ABB05ZRZKE0
  const changeErrText = (text) => {
    setTextErr(text);
    setErrt(true);
    setTimeout(() => setErrt(false), 2000);
  };

  const submitIdUserInvite = (id) => {
    API.request({
      ...API_ROUTER.teams.setIdUserInvite,
      pathKeys: {
        teamId: teamId,
      },
      data: {
        inviteeId: id,
      },
    })
      .then((res) => {
        setAnswerFromServer("You have successfully invite user");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        if (err.data.message === "no transport supports") {
          setAnswerFromServer("You have successfully invite user");
          setTimeout(() => setAnswerFromServer(""), 2000);
        }
        if (err.data.message === "user not found") {
          changeErrText("User not found");
        }
        if (err.data.message === "user already in team") {
          changeErrText("user already in team");
        } else {
          changeErrText("error");
        }
      });
  };
  const checkDataUserInvite = (text) => {
    if (/[a-zA-Z]/.test(text) && text.length > 3) {
      submitIdUserInvite(text);
      setInputText("");
      setErrt(false);
      setTextErr("");
    } else {
      setErrt(true);
      setTextErr("Incorrect data");
    }
  };

  return (
    <div className={s.containerInviteTeam}>
      <div className={s.contantContainer}>
        {team?.hasAccessEdit && (
          <div className={s.inputContainer}>
            <input
              className={err && s.err}
              type="text"
              placeholder={`Add member ID (Email)`}
              value={inputText}
              onChange={(e) => setInputText(e.target.value.trim())}
            />
            <button onClick={() => checkDataUserInvite(inputText)}>
              <FormattedMessage id="global.buttons.add" />
            </button>
          </div>
        )}
        <div className={s.messageContainer}>
          {err && <div className={s.err}>{errText}</div>}
          {answerFromServer && <div>{answerFromServer}</div>}
        </div>

        <div className={s.teamMembersContainer}>
          <div className={s.titleMembers}>
            {" "}
            <FormattedMessage id="id.teamMember" />
          </div>
          <div className={s.playerContainers}>
            {members?.map((el) => (
              <MemberItem
                key={el.id}
                url={el.url}
                id={el.id}
                avatar={el.avatar}
                nickname={el.nickname}
                teamId={teamId}
                ownerTeam={team?.hasAccessEdit}
                getMembers={getMembers}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default InviteTeam;
