import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";

import Wrapper from "../../../../../wrapper";
import { Modal, Button } from "../../../../../UI";
import { isAuthenticated } from "../../../../../../helpers";

import modalLogo from "../../../../../../assets/images/passport-logo/passport-logo-clean.png";
import styles from "./styles.module.scss";

const BracketModal = ({
  open,
  matchItem,
  setNextMatchId,
  setNextLoserMatchId,
  setOpenBracketModal,
  scrollToErrorField,
  setLoserMatchRef,
}) => {
  const goToMatchTicker = (id) => {
    if (id) {
      return history.push(`/match-discussion/${id}`);
    }
  };
  let history = useHistory();
  const goToLoserMatch = () => {
    setNextLoserMatchId(matchItem?.nextLoserMatchId);
    setLoserMatchRef(true);
    scrollToErrorField();
    setOpenBracketModal(!open);
  };
  const goToWinnerMatch = () => {
    setNextMatchId(matchItem?.nextMatchId);
    setLoserMatchRef(false);
    scrollToErrorField();
    setOpenBracketModal(!open);
  };
  // функция для получения даты
  const getEndMatchDate = () => {
    const testDateUtc = moment.utc(
      matchItem?.endedAt ? matchItem?.endedAt : matchItem?.startedAt
    );
    return moment(testDateUtc).local().format("D MMMM YYYY, h:mm a (UTC Z)");
  };

  const getStartMatchDate = () => {
    const testDateUtc = moment.utc(matchItem?.startedAt);
    return moment(testDateUtc).local().format("D MMMM YYYY, h:mm a (UTC Z)");
  };
  return (
    <Wrapper>
      <Modal
        className={styles.modal__wrapper}
        isTransparent={true}
        open={open}
        onClose={() => setOpenBracketModal(!open)}
      >
        <div className={classNames("modalBg", styles.modal)}>
          <div>
            <div>
              {matchItem?.endedAt
                ? "Ended : " + getEndMatchDate()
                : "Start : " + getStartMatchDate()}{" "}
              - {matchItem?.mode ? matchItem?.mode : ""}
            </div>
            <div
              style={{ color: "rgb(255 255 255 / 70%)", margin: "10px 0px" }}
            >
              {matchItem?.name ? matchItem?.name : ""}
            </div>
          </div>
          <img
            className={classNames("modalLogo", styles.modal__image)}
            alt="modal__image"
          />

          <div className={styles.modal__title}>
            <div className={styles.modal__title__buttons}>
              <div className={styles.match__buttons}>
                {matchItem?.name && (
                  <Button
                    label="GO TO MATCH"
                    type="button"
                    color="white"
                    active
                    size="sm"
                    variant="changePassword"
                    action={() => {
                      goToMatchTicker(matchItem?.name);
                    }}
                  />
                )}
              </div>

              <div className={styles.go__to__buttons}>
                {matchItem?.nextLoserMatchId && (
                  <div className={styles.loserMatch}>
                    <Button
                      label="LOSER MATCH"
                      type="button"
                      color="white"
                      active
                      size="sm"
                      variant="changePassword"
                      action={() => {
                        goToLoserMatch();
                      }}
                    />
                  </div>
                )}

                <div className={styles.winnerMatch}>
                  {matchItem?.nextMatchId && (
                    <Button
                      label="WINNER MATCH"
                      type="button"
                      color="white"
                      active
                      size="sm"
                      variant="changePassword"
                      action={() => {
                        goToWinnerMatch();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.close__button}>
              <p onClick={() => setOpenBracketModal(!open)}>CLOSE</p>
            </div>
            <div className={styles.support__button}>
              <a href="https://about.passport.gg/supportpage" target={"_blank"}>
                <FormattedMessage id="footer.support" />
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default BracketModal;
