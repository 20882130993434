import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import styles from "./styles.module.scss";

const ImagesComponent = ({
  partnerLogo,
  sponsorLogo,
  anyPhotos,
  organizerLogo,
  images,
}) => {
  return (
    <>
      <h4 className={styles.events__about__title}>
        {!!partnerLogo && <FormattedMessage id="community.partners" />}
        {!!sponsorLogo && <FormattedMessage id="community.sponsors" />}
        {!!anyPhotos && <FormattedMessage id="community.photos" />}
        {!!organizerLogo && <FormattedMessage id="community.organizer" />}
      </h4>
      <article
        className={classNames("eventBodyBg", styles.events__organizer__team)}
      >
        <div
          className={styles.events__games__container}
          style={{ minHeight: "0" }}
        >
          {images?.map((el) => (
            <img
              className={styles.organizer__image}
              src={el}
              alt=""
              style={{
                width: 100,
                height: 100,
                borderRadius: "24px",
              }}
            />
          ))}
        </div>
      </article>
    </>
  );
};

export default ImagesComponent;
