import { useHistory } from "react-router-dom";
import classNames from "classnames";

import styled from "styled-components";

export default function Rulebok() {
  let history = useHistory();

  return (
    <StyledAuth className={classNames("modalBg")}>
      <h2
        style={{
          padding: "20px 0px 20px 0px",
          textAlign: "end",
          lineHeight: "1",
        }}
      >
        May 2022 WIP - not final
      </h2>
      <h1
        style={{
          padding: "20px 0px 20px 0px",
          textAlign: "center",
          lineHeight: "1",
        }}
      >
        Asia Open Championship 2022
      </h1>
      <h1 style={{ padding: "20px 0px 20px 0px", textAlign: "center" }}>
        Rulebook
      </h1>
      <h2
        style={{
          padding: "20px 0px 20px 0px",
          textAlign: "center",
          lineHeight: "1",
        }}
      >
        Table of contents
      </h2>
      <ul>
        <li
          style={{
            margin: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          1. Introduction
        </li>
        <li
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          2. General Overview
        </li>
        <li style={{ padding: "10px 0px 10px 0px" }}>2.1. Definitions</li>
        <li style={{ padding: "5px 0px 5px 0px" }}> 2.2. Prize money</li>
        <li style={{ padding: "5px 0px 5px 0px" }}> 2.3. Rule Changes</li>
        <li style={{ padding: "5px 0px 5px 0px" }}> 2.4. Confidentiality</li>
        <li style={{ padding: "5px 0px 5px 0px" }}>
          {" "}
          2.5. Participation Eligibility
        </li>
        <li
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          3. Championship structure
        </li>
        <li style={{ padding: "5px 0px 5px 0px" }}>
          3.1. Clash Royale tournaments.
        </li>
        <li style={{ padding: "5px 0px 5px 0px" }}>
          3.2 Clash of Clans tournaments.
        </li>
        <li style={{ padding: "5px 0px 5px 0px" }}>
          3.3 Critical Ops tournaments.
        </li>
        <li style={{ padding: "5px 0px 5px 0px" }}>
          3.4 PUBG Mobile tournaments.
        </li>
        <li
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          4. Code of Conduct
        </li>
        <li
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          5. Penalties
        </li>
        <li
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          6. Legal Aspects
        </li>
        <li
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          7. Personal data
        </li>
        <li
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          8. Media
        </li>
        <li
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          9. Disputes
        </li>
      </ul>
      <hr />
      <h3
        style={{
          padding: "10px 0px 10px 0px",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        1. Introduction
      </h3>
      <span>
        These Official Rules (“Rules”) of the Asia Open Championship 2022
        (“Championship”) apply to each person participating in the Tournaments
        of the Championship (“Players” participating in “Tournaments”) and to
        all Championship officials. All Players must agree to abide by the
        conditions set forth in this document, with the understanding that any
        violation of the Rules may result in immediate disqualification and
        forfeiture of all prize money earned as determined by Championship
        organizers ("Organizers”). By competing in the Championship you agree to
        the following. The Championship Organizers (“Organizers”) in their sole
        discretion: (a) may update, amend, or supplement these Rules at any
        time, by publishing the updated version on the Tournament Hub, and (b)
        may interpret or apply these Rules by releasing notices, online
        postings, e-mails, or other electronic communications that provide
        instructions and guidance to the Players. By participating in the
        Tournaments, the Player accepts, agrees and undertakes to comply with
        the Rules.
        <h3
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          2. General Overview{" "}
        </h3>
        <h4 style={{ padding: "5px 0px 5px 0px" }}> 2.1 Definitions </h4>{" "}
        Championship: refers to Asia Open Championship 2022. Tournaments: refers
        to esports tournaments that take place during the Championship,
        including Clash Royale, Clash of Clans, PUBG Mobile and Critical Ops
        tournaments for Amateur, Semi-pro and Professional players. Organizers:
        refers to New Horizons Alliance (NHA) and other parties authorized by
        them. Championship officials: judges, administrators, casters, managers
        and other persons appointed by Organizers to run the Championship.
        Player: refers to a physical person participating in the Tournament as a
        Participant; Participant: a Player (for Clash Royale Tournaments) or a
        team of Players (for Clash of Clans, Critical Ops and PUBG Mobile
        Tournaments) duly registered to participate in a Tournament. Single
        Elimination: participants will compete in a bracket format where a
        participant is eliminated from competition after one (1) loss. Double
        Elimination: participants will compete in a bracket format using two
        brackets, a winner’s bracket (for participants without a loss) and a
        lower bracket (for participants with one (1) loss) where a participant
        is eliminated from competition after two (2) losses. Tournament Hub:
        Passport.gg, web competition platform where Tournaments registration,
        scheduling, event information, and match setup are available.{" "}
        <h4 style={{ padding: "5px 0px 5px 0px" }}> 2.2 Prize money</h4>
        The total prize pool of the Championship is 100 000 (one hundred
        thousand) US dollars. This prize pool is split evenly between four
        games: Clash Royale, Clash of Clans, PUBG Mobile and Critical Ops, so
        that the prize pool for each game is 25 000 (twenty five thousands) US
        dollars. For each game, the prize pool is split between Professional,
        Semi-pro and Amateur tournaments. The prize pool for each Professional
        Tournament is 15 000 (fifteen thousands) US dollars with the following
        placement split: 1st place: 8 000 (eight thousands) US dollars; 2nd
        place: 4 000 (four thousands) US dollars; 3rd place: 2 000 (two
        thousands) US dollars; 4th place: 1 000 (one thousand) US dollars. The
        prize pool for each Semi-Pro Tournament is 7 000 (seven thousands) US
        dollars with the following placement split: 1st place: 3 800 (three
        thousands eight hundred) US dollars; 2nd place: 1 800 (one thousand
        eight hundred) US dollars; 3rd place: 900 (nine hundred) US dollars; 4th
        place: 500 (five hundred) US dollars. The prize pool for each Amateur
        Tournament is 3 000 (three thousands) US dollars with the following
        placement split: 1st place: 1 600 (one thousand six hundred) US dollars;
        2nd place: 800 (eight hundred) US dollars; 3rd place: 400 (four hundred)
        US dollars; 4th place: 200 (two hundred) US dollars. For team
        Tournaments (Clash of Clans, PUBG Mobile and Critical Ops) the prize is
        split evenly between all members of the registered roster. If a player
        qualifies for prizing, such Player will be contacted by Championship
        officials in order to begin prizing payment processing. If a team
        qualifies for prizing, team Players or team captains will be contacted
        in order to begin prizing payment processing. Teams must submit payment
        information within seven (7) days of the completion of the competition.
        Prize money will be paid out (withholding appropriate taxes) 90 days
        upon receipt of complete and correct payment information. Players shall
        execute any document which is deemed necessary for payment by
        Championship officials. Players are responsible for any and all costs
        and expenses associated with their prize that are not specifically set
        forth herein. International, national, state and local taxes, including
        possible VAT taxes, and any duties and similar costs which are
        associated with the receipt or use of any prizes are the sole
        responsibility of the Player. Championship officials are entitled, and
        Player hereby authorizes the Championship officials, to withhold (or
        cause there to be withheld, as the case may be) from any amounts
        otherwise due or payable as prize to Player such national, provincial,
        local or any other income, employment, or other taxes as may be required
        to be withheld pursuant to any applicable law or regulation.
        <h4 style={{ padding: "5px 0px 5px 0px" }}>2.3 Rule Changes</h4>
        Organizers reserve the right to amend, remove, or otherwise change the
        rules outlined in these Rules by publishing the updated version on the
        Tournament Hub. Organizers also reserve the right to make judgment on
        cases that are not explicitly supported or detailed in these Rules, or
        in extraordinary cases, judgements that may even go against these Rules
        to preserve fair play and sportsmanship. The Organizer has the right at
        any time to terminate the Championship by publishing the corresponding
        change on the Tournament Hub or otherwise publicly notify about such
        termination.
        <h4 style={{ padding: "5px 0px 5px 0px" }}>2.4 Confidentiality</h4>
        All content including protests, support tickets, discussions and/or any
        other correspondence with Organizers are deemed strictly confidential.
        The publication of such material is prohibited without prior written
        consent from Organizers. Publication of the aforementioned material
        without prior written consent may result in penalty points.
        <h4 style={{ padding: "5px 0px 5px 0px" }}>
          {" "}
          2.5 Participation Eligibility
        </h4>
        Players must be citizens or legal residents of the following countries
        in order to participate: Bahrein, Brunei, Cambodia, China, Hong Kong,
        India, Indonesia, Iran, Iraq, Japan, Jordan, Kazakhstan, Korea, Kuwait,
        Kyrgyzstan, Laos, Lebanon, Macau, Malaysia, Maldives, Mongolia, Myanmar,
        Nepal, Pakistan, Philippines, Saudi Arabia, Singapore, Sri Lanka, Syria,
        Chinese Taipei, Thailand, Turkmenistan, United Arab Emirates,
        Uzbekistan, Vietnam. Players must be 12 years of age or older in order
        to participate. Any Player considered to be a minor by laws of the
        respective country will be required to be able to confirm that they have
        parental or guardian consent to participate in the Championship.
        Additional requirements and (or) restrictions might be implemented by
        Organizers to conform to the laws and regulations of the respective
        country or game publisher. Players who compete in the tournament may be
        required to provide proof of identity. Failure to provide proof of
        identity may result in ineligibility for participation in the Tournament
        or disqualification of a Player (and their Team, if applicable) from the
        Tournament. The Championship officials reserves the right to verify an
        eligible Player’s information at any given time if deemed necessary.
        During the verification process, players must be able to submit an
        acceptable photo ID to Championship officials to verify their identity.
        Employees of Organizers and partner companies, as well as family members
        of such employees, are not eligible to register or compete in the
        Championship. For tournaments of Clash of Clans and Critical Ops teams
        are required to register a roster of 5 players. For tournaments of PUBG
        Mobile teams are required to register a roster of 4 players.
        Substitutions and team roster changes are not allowed during the
        Championship except under force majeure circumstances. All such requests
        should be submitted to Championship officials to be evaluated on a
        case-by-case basis. Each player can only participate in one Clash Royale
        Tournament during the Championship. The same applies to Clash of Clans
        Tournaments, Critical OPS Tournaments, PUBG Mobile Tournaments. To
        participate in the Tournament, a Player must meet the following
        conditions: Be registered on the Tournament Hub; Accepted the Rules,
        Tournament Hub's terms of use, privacy policy and cookie policy; The age
        of the Player shall correspond to the minimum allowed age for the
        corresponding Tournament. Have a device and official game account for
        the respective game that conform to these Rules. Player is fully
        responsible for compliance with national laws regulating minimum age
        requirements. Playershall not register and/or participate in the
        Tournament, if Player’s national law or applicable regulations prohibit
        such participation. In order to maintain the integrity of the
        Championship and enforce fair play for all participants Organizers may,
        at their sole discretion, at any stage of the Championship require any
        of the Participants to provide a video recording and (or) a video
        broadcast verifying the identity of Participants and programs and
        devices used by Participants; in this case, Championship officials
        provide Participants specific instructions on the setup and output of
        such video recording or broadcast no later than 24 hours before
        respective match starts.
        <h3
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          3. Championship structure
        </h3>
        <h4 style={{ padding: "5px 0px 5px 0px" }}>
          3.1 Clash Royale tournaments.
        </h4>
        Each Clash Royale Tournament (Professional, Semi-Pro, Amateur) consists
        of the Group stage, the Semi-final stage and the Final stage. The
        schedule for each of the Clash Royale Tournaments is announced at the
        Tournament Hub. During the Group stage all participants are divided into
        groups. The size and amount of the groups are contingent on the amount
        of participants registered for the respective Tournament. By default the
        maximum size of each group is 128 participants, with 2 participants
        advancing from each group. For the Amateur and Semi-Pro Tournaments, if
        there are more than 32 groups in a specific Tournament, Organizers may
        decide to change the number of participants advancing from each group
        and (or) run an additional stage(s) until 64 (or less) advancing
        participants are determined. For the Professional Tournament, if there
        are more than 64 groups in a specific Tournament, Organizers may decide
        to change the number of participants advancing from each group and (or)
        run an additional stage(s) until 128 (or less) advancing participants
        are determined. During the Group stage, in each group participants are
        randomly seeded into single elimination brackets. During the Semi-final
        all participants are divided into eight groups. In each group
        participants are randomly seeded into full double elimination brackets.
        For the Amateur and Semi-Pro Tournaments, the winner of each group
        advances to the Final stage. For the Professional Tournament, the winner
        and the runner-up of each group advance to the Final stage. During the
        Final stage, eight participants are randomly seeded into full double
        elimination brackets. Game version. All players must install the latest
        version of the game on a mobile device (smartphone or tablet). All
        matches are played on the current patch available on the live servers at
        the time of the match. Matches format. For each match participants
        create 4 unique decks (no repeated cards) and play each game of the best
        of three match with a different deck, without pre-match deck bans. Game
        settings. Each game is limited to 5 minutes. The king and cards should
        be at maximum (11) level.
        <h4 style={{ padding: "5px 0px 5px 0px" }}>
          3.2 Clash of Clans tournaments.
        </h4>
        Each Clash of Clans Tournament (Professional, Semi-Pro, Amateur)
        consists of the Group stage, the Semi-final stage and the Final stage.
        The schedule for each of the Clash of Clans Tournaments is announced at
        the Tournament Hub. During the Group stage all participants are divided
        into groups. The size and amount of the groups are contingent on the
        amount of participants registered for the respective Tournament. By
        default the maximum size of each group is 64 participants, with 2
        participants advancing from each group. For the Amateur and Semi-Pro
        Tournaments, if there are more than 16 groups in a specific Tournament,
        Organizers may decide to change the number of participants advancing
        from each group and (or) run an additional stage(s) until 32 (or less)
        advancing participants are determined. For the Professional Tournament,
        if there are more than 32 groups in a specific Tournament, Organizers
        may decide to change the number of participants advancing from each
        group and (or) run an additional stage(s) until 64 (or less) advancing
        participants are determined. During the Group stage, in each group
        participants are randomly seeded into single elimination brackets.
        During the Semi-final stage all participants are divided into eight
        groups. In each group participants are randomly seeded into full double
        elimination brackets. For the Amateur, Semi-Pro and Professional
        Tournaments, the winner and the runner-up of each group advance to the
        Final stage. During the Final stage, eight participants are randomly
        seeded into full double elimination brackets. Matches format. All
        matches are played in 5v5 Friendly War mode, best of 1 games. Players
        can only attack once during each match. For the final stage of each
        Tournament, Tournament officials may, at their discretion, enforce an
        specific attack timetable for the match; in such case, this timetable
        would be made available to the match participants no later than 24 hours
        before the match starts. After each match the winner of the match is
        determined by the following criteria (in order): 1. Highest star total.
        2. Highest destruction percentage. 3. Fastest average attack duration.
        If after the match the winner could not be determined based on the
        criteria above, teams will compete in a Best-of-1 Friendly Challenge.
        Each team will elect a single Team Member to participate using one (1)
        attack per player. The above criteria are applied to the results of this
        Friendly Challenge. This process repeats until a clear winner can be
        determined. Game version. All players must install the latest version of
        the game on a mobile device (smartphone or tablet). All matches are
        played on the current patch available on the live servers at the time of
        the match. Game settings. Time limits: 5 minutes for preparation (base
        layouts, donate reinforcement troops, and scout enemy base). 30 minutes
        for attacks. All accounts must have the highest possible Town Hall level
        currently available in the game.
        <h4 style={{ padding: "5px 0px 5px 0px" }}>
          3.3 Critical Ops tournaments.
        </h4>
        Each Critical Ops Tournament (Professional, Semi-Pro, Amateur) consists
        of the Group stage, the Semi-final stage and the Final stage. The
        schedule for each of the Critical Ops Tournaments is announced at the
        Tournament Hub. During the Group stage all participants are divided into
        groups. The size and amount of the groups are contingent on the amount
        of participants registered for the respective Tournament. By default the
        maximum size of each group is 64 participants, with 2 participants
        advancing from each group. For the Amateur and Semi-Pro Tournaments, if
        there are more than 16 groups in a specific Tournament, Organizers may
        decide to change the number of participants advancing from each group
        and (or) run an additional stage(s) until 32 (or less) advancing
        participants are determined. For the Professional Tournament, if there
        are more than 32 groups in a specific Tournament, Organizers may decide
        to change the number of participants advancing from each group and (or)
        run an additional stage(s) until 64 (or less) advancing participants are
        determined. During the Group stage, in each group participants are
        randomly seeded into single elimination brackets. During the Semi-final
        stage all participants are divided into eight groups. In each group
        participants are randomly seeded into full double elimination brackets.
        For the Amateur, Semi-Pro and Professional Tournaments, the winner and
        the runner-up of each group advance to the Final stage. During the Final
        stage, eight participants are randomly seeded into full double
        elimination brackets. Game version. All players must install the latest
        version of the game on a mobile device (smartphone or tablet). All
        matches are played on the current patch available on the live servers at
        the time of the match. Players are forbidden to use any 3rd party
        software that interferes with game encryption (including VPN services).
        Players are forbidden to use “jailbroken” or “rooted” devices. Each
        player must use a Critical Ops account that was created at least 3
        months before the start of the respective tournament. This account must
        have at least 5 000 kills. Matches format. All matches are played in 5v5
        Defuse mode, best of 1 games. Game settings. Round time 1 minute 50
        seconds, Freeze time ON, Rounds to win 13, Start money 800, Infinite buy
        time OFF, Auto balance OFF, Start with armor OFF, Allowed weapons
        Default Critical Ops Defuse Format (Faction Weapons). Map pool: Bureau,
        Legacy, Plaza, Grounded, Canals, Port, Raid, Soar, Village. Best of one
        map (BO1) ban&pick order: Captain 1 – ban a map, Captain 2 – ban a map,
        Captain 1 – ban a map, Captain 2 – ban a map, Captain 1 – ban a map,
        Captain 2 – ban a map, Captain 1 – ban a map, Captain 2 – ban a map.
        Play the remaining map, knife round for sides. Server is selected by the
        mutual agreement of participants. If the agreement cannot be reached,
        captains ban servers until one server remains; the Captain who was the
        second to ban map is the first to ban the server.
        <h4 style={{ padding: "5px 0px 5px 0px" }}>
          {" "}
          3.4 PUBG Mobile tournaments.
        </h4>
        Each PUBG Mobile Tournament (Professional, Semi-Pro, Amateur) consists
        of the Group stage, the Semi-final stage and the Final stage. The
        schedule for each of the PUBG Mobile Tournaments is announced at the
        Tournament Hub. During the Group stage all participants are divided into
        groups. The size and amount of the groups are contingent on the amount
        of participants registered for the respective Tournament. By default the
        maximum size of each group is 25 participants, with 3 participants
        advancing from each group. Each group plays a match of three games. For
        the Amateur and Semi-Pro Tournaments, if there are more than 32 groups
        in a specific Tournament, Organizers may decide to change the number of
        participants advancing from each group and (or) run an additional
        stage(s) until 96 (or less) advancing participants are determined. For
        the Professional Tournament, if there are more than 80 groups in a
        specific Tournament, Organizers may decide to change the number of
        participants advancing from each group and (or) run an additional
        stage(s) until 240 (or less) advancing participants are determined.
        During the Semi-final stage all participants are divided into groups.
        Each group plays a match of three games. For the Amateur and Semi-Pro
        tournaments there will be 4 groups, with 5 participants advancing from
        each group. For the Professional Tournament there will be 10 groups,
        with 2 participants advancing from each group. During the Final stage,
        twenty participants are in one group that plays a match of seven games.
        Game version. All players must install the latest version of the game on
        a mobile device (smartphone or tablet). All matches are played on the
        current patch available on the live servers at the time of the match.
        For all matches for all games the following point distribution is used:
        1st place: 15 points; 2nd place: 12 points; 3rd place: 10 points; 4th
        place: 8 points; 5th place: 6 points; 6th place: 4 points; 7th place: 2
        points; 8-12th places: 1 point; 13-25th places: 0 points; Additionally,
        each kill grants 1 point. Tiebreakers.Ties between two teams will be
        determined, if necessary, in the order of total times of winning the
        first placement across all games of the match, then the order of the
        second placement across all games of the match, etc. until the tie is
        broken. Matches format. Classic mode, Squads (4 players each), third
        person mode. For the Group stage and the Semi-final stage, each match is
        a best of 3 games, map order: Erangel, Miramar, Sanhok. For the Final
        stage match is a best of 7 games, map order: Erangel, Miramar, Sanhok,
        Vikendi, Sanhok, Miramar, Erangel.
        <h3
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          4. Code of Conduct
        </h3>
        The following behaviors are considered unfair play and will be subject
        to penalties including disqualifications. Collusion, match-fixing -
        agreement with a participant of a match to alter the result of a match.
        Cheating - using software or hardware to alter the game mechanics or
        behavior for any player in order to gain benefits otherwise unavailable
        in-game. Bug exploitation - intentional usage of any in-game bug to gain
        in-game advantage. Multi-accounting - using multiple accounts or
        accounts of other players for the matches of the Championship. Bribery -
        offering any gifts or rewards to members and workers of Organizers for
        services promised or rendered in relation to the Championship. Abuse,
        threats, discrimination, vulgar or hateful speech - using offensive
        language during communication with other Championship players or
        Championship officials. Gambling - participation in betting or gambling
        on the outcome of the Championship or any part of the Championship.
        <h3
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          5. Penalties
        </h3>
        Any person that had engaged or attempted to engage in an act that
        violates or potentially violates Code of Conduct might be subject to a
        penalty. Decision on whether a specific act or attempt violates or
        potentially violates Code of Conduct is made by Championship officials
        at their sole and absolute discretion. Penalties include, but not
        limited to: warning, game loss, match loss, point subtraction,
        disqualification. Repeated infractions might lead to escalating
        penalties. Penalties are not always imposed in a successive manner.
        Teams receive penalties for infractions committed by their players.
        <h3
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          6. Legal aspects
        </h3>
        When registering for and (or) participating in the Tournaments, each
        Player acknowledges and agrees that: The Organizer has the right to use
        Player’s name, nickname, biography, voice, photos and videos with
        Player’s participation in the Tournament on the websites, as well as in
        any public and promotional events or other promotions without reference
        to the source, additional notifications or compensation; The Organizer
        has the right to process, store and use personal and contact information
        of the Player for the purposes of organizing, conducting and promotion
        of the Tournament, including for communication with the Player; The
        Organizer is not responsible for any damage caused to Player, except for
        liability that cannot be excluded in accordance with applicable law; The
        Tournament may be broadcasted; Ensuring public order and public safety
        during the competition is carried out in accordance with the
        requirements of regulatory legal acts in the field of security and
        public order; To ensure public order and public safety, the competition
        organizers and/or the owners of the facility may involve private
        security companies, distribution controllers, etc. The Player has the
        right to receive information about the Tournament via the Tournament
        Hub. The participant undertakes to independently track information about
        the Tournament in any way provided for in paragraph 6.2 of these Rules.
        <h3
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {" "}
          7. Personal data
        </h3>
        Player (hereinafter referred to as "personal data subject") shall
        provide their personal data. Personal data is collected for the purpose
        of registration for and organization of the Tournament, issuing prizes
        to Player (if applicable), informing Participants of their winnings,
        advertising offers, as well as for the purpose of conducting marketing
        analysis and preparing statistical information. Personal data may be
        processed by the Organizer and/or partners and / or their affiliates, as
        well as their authorized persons, using automated data processing tools.
        The Participant has the right to obtain other information about the
        person who processes the Player's personal data by contacting the
        Organizer. The personal data subject has the right to receive
        information about the Organizer, its location, whether the Organizer has
        personal data related to the relevant personal data subject, as well as
        to get acquainted with such personal data, as in writing (upon a
        corresponding request containing the number of the main identity
        document of the personal data subject or its legal representative,
        information about the date of issue of the specified document and the
        issuing authority, as well as the handwritten signature of the subject
        of personal data or its legal representative). The Organizer, who has
        access to personal data, ensures the confidentiality of personal data in
        accordance with the procedure established by law. The data subject may
        revoke his consent by sending an email to a person designated by the
        Organizer with the indication in the notification of Participant’s name,
        surname, patronymic, email, age and city. The Organizer has the right to
        request data that allows identification of the personal data subject. In
        that case, Player's participation in the Tournament may be canceled. The
        fact that Player registers for the Tournament, confirms the Player's
        consent to these Rules, and is also the Player's consent to provide the
        Organizer with Player's personal data, their processing (including
        collection, systematization, accumulation, storage (including in case of
        claims), clarification (updating, modification), use (including, but not
        limited to, for the purpose of awarding prize (if applicable),
        individual communication with Players and providing advertising
        information about products, services and/or the Organizer and/or its
        partners), distribution (including transfer to third parties), transfer,
        including cross-border, depersonalization, blocking, and destruction).
        Personal data may be transferred from the Organizer to third parties
        engaged by the Organizer on the basis of relevant agreements. By
        participating in the Tournament, the participant agrees to the use by
        the Organizer and/or its partners and/or their authorized representative
        personal information of the Player (material about him) and authorizes
        the Organizer to take advertising interviews about participation in
        Tournament by the Player, including radio and television, as well as for
        other media, and distribute them or to make film, video, audio recording
        party for making any advertising materials, disclosure and further use
        of the names, surnames, biography, image, voice of the Player without
        paying any remuneration for this. This consent is given by the
        participant for the duration of the Tournament and for 5 years after its
        end, and may be withdrawn by the Player by written notification sent to
        the Organizer by registered mail. The withdrawal of consent to the
        processing of personal data by the Participant and/or other personal
        data subject whose personal data was provided by the Player to the
        Organizer/ its partners (or its affiliates) automatically leads to the
        withdrawal of the relevant Player from participation in the Tournament
        and makes it impossible to receive the prize (if applicable).
        <h3
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          8. Media
        </h3>
        Accreditation of journalists is carried out by the Organizer.
        Participants are required to take part in interviews before and after
        matches at the request of the Organizer. Communication with media
        representatives is organized by the Organizer in special zones equipped
        with banners with Tournament sponsors. Media representatives should
        respect the Organizer and participants of the Tournament. Interviews can
        only be arranged in strictly defined and designated locations.
        Journalists should not approach Participants for interviews or comments
        during the match.
        <h3
          style={{
            padding: "10px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          9. Disputes
        </h3>
        All issues that are not regulated by these Rules are resolved by the
        Organizer in accordance with applicable law. Complaints related to the
        refereeing and organization of matches are submitted in any written form
        to the Championship officials of the Tournament no later than 10 minutes
        after the end of the match. In this case, the Player shall declare
        intention to file a complaint. The complaint is considered by the
        Championship officials and/or the Organizer in the shortest possible
        time, which shall not exceed ten (10) business days, unless additional
        investigation is required by the Championship officials, referee or the
        Organizer. The final decision of the Championship officials and/or the
        Organizer on disputed situations is not subject to appeal. The Organizer
        shall have the ultimate decision role in all disputes.
      </span>
    </StyledAuth>
  );
}
const StyledAuth = styled.div`
  background-size: 130%;
  min-height: 100vh;
  padding: 85px 28px;

  & > .auth__text {
    text-align: center;
    margin: 42px 0;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
  }

  & > .auth__form {
    border: 1px solid #999999;
    padding: 16px 12px;
    border-radius: 16px;

    & > .sign__up {
      text-align: center;
      color: #999999;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      margin-bottom: 16px;

      & > span {
        color: #f7a01d;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: -0.56px;
        line-height: normal;
        cursor: pointer;
        margin-left: 16px;
      }
    }

    & > .access__wrapper {
      margin-bottom: 30px;

      & > div {
        display: flex;
        align-items: flex-start;

        &:first-of-type {
          margin-bottom: 30px;
        }
      }
    }

    & > .input__group {
      border-radius: 16px;
      overflow: hidden;
      margin-bottom: 25px;

      & > .input__group__border {
        border-top: 1px solid #999999;
      }
    }

    & > .btn__group {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    & > .forgot__password {
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: -0.56px;
      line-height: normal;
      text-align: center;
      text-decoration: underline;
      margin-top: 28px;
      cursor: pointer;
    }
  }

  & > .social__auth {
    margin-top: 25px;

    & > .social__label {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: -0.61px;
      line-height: normal;
    }

    & > .social__wrapper {
      margin-left: 30px;
      display: flex;
      align-items: center;

      & > .google,
      & > .facebook {
        padding: 13px 25px 10px;
        border-radius: 20px;
        background-color: #f26052;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        & > i {
          width: 23px;
          height: 23px;
        }
      }

      & > .google {
        padding: 14px 15px 10px;
      }

      & > .facebook {
        background-color: #3669a5;
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 400px) {
    padding: 50px 1rem;

    & > .auth__form {
      & > .btn__group {
        flex-direction: column;
        gap: 10px;

        & > button {
          width: 100%;
        }
      }
    }

    & > .social__auth {
      //flex-direction: column;

      & > .social__wrapper {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
`;
