import styles from "../styles.module.scss";
export function fulldate(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const months = [
    "JAN",
    "FEB",
    "MARC",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEPT",
    "OCT",
    "NOV",
    "DEC",
  ];

  const dateStart = start.getDate();
  const monthNameStart = months[start.getMonth()];
  const YearNameStart = start.getFullYear();
  const fullData = [dateStart, " ", monthNameStart, " ", YearNameStart];
  const dateEnd = end.getDate();
  const monthNameEnd = months[end.getMonth()];
  const YearNameEnd = end.getFullYear();
  const fullDataEnd = [dateEnd, " ", monthNameEnd, " ", YearNameEnd];
  return (
    <>
      <p className={styles.events__date__text}>{fullData ? fullData : ""}</p>
      {fullDataEnd && <p>-</p>}
      <p className={styles.events__date__text}>
        {fullDataEnd ? fullDataEnd : ""}
      </p>
    </>
  );
}
