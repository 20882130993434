import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import { Button } from "../../components/UI";
import { API, API_ROUTER } from "../../api";

function activateAccount() {
  API.request({
    ...API_ROUTER.user.activateAccount,
  })
    .then((res) => {
      toast.success(res.message);
    })
    .catch((err) => console.log(err));
}

const Msg = ({ closeToast, toastProps }) => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    <p style={{ marginBottom: "10px", paddingBottom: "10px" }}>
      You need to confirm your registration email in the welcome email sent to
      you. If you don't have a welcome email, please resend it by clicking the
      button below
    </p>

    <Button
      label={<FormattedMessage id="global.forms.labels.send.email" />}
      type="button"
      color="white"
      active
      size="sm"
      variant="changePassword"
      action={() => {
        activateAccount();
      }}
    />
  </div>
);

function ContextExample() {
  return toast(<Msg />, {
    draggable: true,
    closeButton: true,
    autoClose: false,
    closeOnClick: false,
    type: "success",
  });
}
export default ContextExample;
