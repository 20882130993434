import { useState } from "react";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { API, API_ROUTER } from "../../../api";

import {
  dataUriToBlob,
  getValueFromSelect,
  isFieldEmpty,
  getArrayFromSelect,
} from "../../../helpers";

import { initialErrors, handleLoadAvatar, reverseDate } from "../const";

import UploadAvatar from "../../../components/upload-avatar";
import { ContentBox, Container, Button, Select } from "../../../components/UI";
import TitleRow from "../../../components/title-row";
import InfoInput from "../../../components/info-input";

import styles from "./styles.module.scss";

const Settings = (props) => {
  const { history, intl } = props;
  const languagesList = useSelector((state) => state.languagesList);
  const countriesList = useSelector((state) => state.countriesList);

  const [errors, setErrors] = useState(initialErrors);
  const [formFields, setFormFields] = useState({});
  const [team, setTeam] = useState(null);
  const [loadedImg, setLoadedImg] = useState(null);

  function selectHandler(value, selectName) {
    setFormFields({ ...formFields, [selectName]: value });
  }

  function inputHandler(e) {
    const { name, value } = e.target;

    setFormFields({
      ...formFields,
      [name]: value,
    });
  }

  function onImageSelect(event) {
    const file = event.target.files[0];
    if (file) {
      handleLoadAvatar(file, setLoadedImg);
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    const arrayFromLanguages = getArrayFromSelect(formFields.languages || []);
    // array to lowerCase
    const languages = arrayFromLanguages.map((el) => {
      return el.toLowerCase();
    });
    const endpoint = API_ROUTER.teams.createTeam;

    // validate
    const newErrors = initialErrors;
    newErrors.name = isFieldEmpty(formFields.name);
    newErrors.country = formFields.country?.length
      ? ""
      : "This field is required";
    newErrors.languages = formFields.languages?.length
      ? ""
      : "This field is required";

    setErrors({ ...errors, ...newErrors });

    console.log("newErrors", newErrors);

    let TEAM_URL;

    if (!Object.values(newErrors).some((value) => value.length)) {
      const data = {
        name: formFields.name,
        about: formFields.about || "Welcome",
        links: formFields.links,
        founded: formFields.founded
          ? reverseDate(formFields.founded)
          : new Date(),
        email: formFields.email,
        tag: formFields.tag || formFields.name,
        country: getValueFromSelect(formFields.country || ""),
        languages: languages,
      };

      API.request({ ...endpoint, data }, true)
        .then((resp) => {
          TEAM_URL = resp.id;

          if (!loadedImg) {
            return;
          }

          const blob = dataUriToBlob(loadedImg);
          const formData = new FormData();
          formData.append("media", blob);
          formData.append("mediaType", "teamLogo");
          formData.append("mediaOwnerId", resp.id);
          return API.request(
            {
              ...API_ROUTER.media.create,
              headers: {
                "Content-type": "multipart/form-data",
              },
              data: formData,
            },
            true
          );
        })
        .then((resp) => history.push(`/teams/team/${TEAM_URL}`))
        .catch((err) => {
          if (err.data?.errors?.name) {
            toast.error("Team name already exists");
          } else toast.error(err.data && err.data.message);
        });
    }
  }

  function renderForm() {
    return (
      <div className={styles.create__team}>
        <header className={styles.create__team__header}>
          <section className={styles.section__second}>
            <div>
              <UploadAvatar
                user={team}
                conditionRendering={(node) => node}
                onImageSelect={onImageSelect}
              />
            </div>
          </section>
        </header>
        <main>
          <form onSubmit={(e) => onSubmit(e)}>
            <section>
              <TitleRow
                title={<FormattedMessage id="settings.education.enterName" />}
                required
                color="white"
                boxshadow
                padding="9px 60px 7px 38px"
              />
              <InfoInput
                placeholder={intl.formatMessage({
                  id: "settings.education.enterName",
                  defaultMessage: "first name",
                })}
                onChange={inputHandler}
                error={errors.name}
                value={formFields.name}
                icon="pencil"
                name="name"
                type="name"
                required
              />
            </section>
            <section>
              <TitleRow // TODO wait back
                title={<FormattedMessage id="roster.add.country" />}
                required
                color="white"
                boxshadow
                padding="9px 60px 7px 38px"
              />

              <Select
                clearable
                error={errors.country}
                values={formFields.country ? formFields.country : []}
                options={countriesList || []}
                onChange={(value) => selectHandler(value, "country")}
                padding
                required
              />
            </section>
            <section>
              <TitleRow
                title={<FormattedMessage id="global.forms.labels.language" />}
                required
                color="white"
                boxshadow
                padding="9px 60px 7px 38px"
              />

              <Select
                clearable
                error={errors.languages}
                values={formFields.languages ? formFields.languages : []}
                value={formFields.languages}
                options={languagesList || []}
                onChange={(value) => selectHandler(value, "languages")}
                padding
                multiple
                required
              />
            </section>
            {/* <section>
              <TitleRow // TODO wait back
                title="Team Tag * EN"
                color="white"
                padding="9px 38px 7px"
                boxshadow
              />
              <p> min 5 simbol (if you want more than 5 simbol, contact us) </p>
              <InfoInput
                // value={formFields.tag}
                // error={errors.tag}
                // onChange={inputHandler}
                // name="tag"
                // icon="pencil"
                language="english"
                icon="password"
                placeholder="Enter team tag"
              />
            </section> */}
            <section>
              <TitleRow // TODO wait back
                title="about"
                color="white"
                padding="9px 38px 7px"
                boxshadow
              />
              <InfoInput
                value={formFields.about}
                error={errors.about}
                onChange={inputHandler}
                name="about"
                icon="pencil"
                placeholder="about"
                textarea
              />
            </section>
          </form>
        </main>
        <footer className={styles.footer}>
          <Button
            label={<FormattedMessage id="teams.createCloseButton" />}
            action={() => history.push(`/more`)}
            type="button"
            color="white"
            active
            size="md"
            variant="secondary"
          />
          <Button
            action={(e) => onSubmit(e)}
            type="button"
            color="yellow"
            active
            size="md"
            label={<FormattedMessage id={`teams.${"createNewTeamButton"}`} />}
          />
        </footer>
      </div>
    );
  }

  if (formFields.error) {
    return (
      <Container>
        <ContentBox>
          <FormattedMessage id="teams.team.notFound" />
        </ContentBox>
      </Container>
    );
  }

  return <div>{renderForm()}</div>;
};

export default withRouter(injectIntl(Settings));
