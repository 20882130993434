import React, { useState } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

//
import Card from "../Card";

import styles from "./styles.module.scss";

export default function Row({ title = "Title", cards = [], requestSuccess }) {
  return (
    <div className={styles.styledRow}>
      <section className={styles.styledRow__header}>
        <h1>{title}</h1>
      </section>
      <section className={styles.styledRow__footer}>
        {!requestSuccess && <CircularProgress />}
        {!!requestSuccess && cards.length ? (
          cards.map((card) => (
            <Card {...card} key={card.id} history={history} />
          ))
        ) : (
          <div className="empty-data">There are no games yet</div>
        )}
      </section>
    </div>
  );
}
