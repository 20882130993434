import { FormattedMessage } from "react-intl";
import gameLogo from "../../../../../../assets/games/apex-legends.png";

import styles from "./styles.module.scss";

const ImagesComponent = ({ partnerLogo, sponsorLogo, anyPhotos, images }) => {
  return (
    <>
      <h4 className={styles.events__about__title}>
        {!!partnerLogo && "Photo"}
        {!!sponsorLogo && <FormattedMessage id="community.sponsors" />}
        {!!anyPhotos && <FormattedMessage id="community.photos" />}
      </h4>
      <article
        className={styles.events__organizer__team}
        style={{ alignItems: "normal" }}
      >
        <div
          className={styles.events__games__container}
          style={{ minHeight: "0" }}
        >
          {/* {images?.map((el) => (
            <img
              className={styles.organizer__image}
              src={el}
              alt=""
              style={{
                width: 100,
                height: 100,
                borderRadius: "24px",
              }}
            />
          ))} */}
          <img
            className={styles.organizer__image}
            src={gameLogo}
            alt=""
            style={{
              width: 100,
              height: 100,
              borderRadius: "24px",
            }}
          />
          <img
            className={styles.organizer__image}
            src={gameLogo}
            alt=""
            style={{
              width: 100,
              height: 100,
              borderRadius: "24px",
            }}
          />
          <img
            className={styles.organizer__image}
            src={gameLogo}
            alt=""
            style={{
              width: 100,
              height: 100,
              borderRadius: "24px",
            }}
          />
          <img
            className={styles.organizer__image}
            src={gameLogo}
            alt=""
            style={{
              width: 100,
              height: 100,
              borderRadius: "24px",
            }}
          />
        </div>
      </article>
    </>
  );
};

export default ImagesComponent;
