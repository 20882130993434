import {FormattedMessage} from "react-intl";

export const COMMUNITY_HAS_PRO_STATUS = [
    {
        id: 1,
        value: <FormattedMessage id="community.status.isPro" />
    },
    {
        id: 0,
        value: <FormattedMessage id="community.status.noPro" />
    }
]
