import styled from "styled-components";
import classNames from "classnames";

export default function Logo() {
  return (
    <StyledLogo>
      <img className={classNames("logoAuth")} alt="logo" style={{}} />
    </StyledLogo>
  );
}

const StyledLogo = styled.div`
  width: max-content;
  margin: 0 auto;

  @media (max-width: 350px) {
    width: 60%;

    & > img {
      width: 100%;
    }
  }
`;
