import React from "react";

import styles from './styles.module.scss';

export const Preloader = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.preloader}></div>
        </div>
    )
};

export default Preloader;
