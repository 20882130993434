import {API_ROUTER} from "../../../api";

export const MATCHES_FILTER = [
    { id: 1, value: "Show all matches", route: API_ROUTER.watch.getAllMatches },
    { id: 2, value: "Show my matches", route: API_ROUTER.watch.getMyMatches },
];

export const MATCHES_STATE_FILTER = [
    { id: "soon", value: "soon" },
    { id: "ended", value: "ended" },
    { id: "live", value: "live" },
    {id: "canceled", value: "canceled"}
];

export const MATCHES_STATUS_FILTER = [
    {id: "future", value: "future"},
    {id: "current", value: "current"},
    {id: "past", value: "past"},
    {id: "canceled", value: "canceled"}
]
