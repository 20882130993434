import { API, API_ROUTER } from "../../api";

const addGame = (userId) => {
  const params = {
    ...API_ROUTER.user.getUserTournaments,
    pathKeys: {
      userId: userId,
    },
  };

  return API.request(params, true);
};

export default addGame;
