import {FormattedMessage} from "react-intl";

export const EVENT_STATUSES = [
    {
        id: "soon",
        value: <FormattedMessage id="events.status.soon" />
    },
    {
        id: "Canceled",
        value: <FormattedMessage id="events.status.canceled" />
    },
    {
        id: "live",
        value: <FormattedMessage id="events.status.live" />
    },
    {
        id: "completed",
        value: <FormattedMessage id="events.status.completed" />
    },
]
