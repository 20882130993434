import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { API, API_ROUTER } from "../../../../api";

import { isAuthenticated } from "../../../../helpers";

import { fulldate } from "./constant/date";

import banerAsia from "../../../../assets/images/event-asia-baner.png";
import asiaLogo from "../../../../assets/asia-logo.jpeg";
import calendar from "../../../../assets/events/calendar.svg";
import point from "../../../../assets/events/point.svg";

import styles from "./styles.module.scss";

function EventHeader({ event }, isFollow) {
  const [star, tongleStar] = useState(isFollow);

  const history = useHistory();

  function goToUrl(url) {
    history.push(url);
    if (!isAuthenticated()) {
      localStorage.removeItem("tournamentId");
      localStorage.removeItem("communityId");
      localStorage.removeItem("profileUrl");
      return localStorage.setItem("eventId", id);
    }
  }
  const tongStar = () => {
    API.request({
      ...API_ROUTER.follow.IsUserFollowEvent,
      pathKeys: {
        eventId: id,
      },
    })
      .then((res) =>
        res.follow === true
          ? API.request({
              ...API_ROUTER.follow.stopFollowEvent,
              pathKeys: {
                eventId: id,
              },
            })
              .then((res) => {
                toast.success(`You unfollowed this event`);
              })
              .catch((err) => console.log(err))
          : API.request({
              ...API_ROUTER.follow.followEvent,
              data: {
                eventId: id,
              },
            })
              .then((res) => {
                toast.success(`You followed this event`);
              })
              .catch((err) => console.log(err))
      )
      .catch((err) => console.log(err));
    if (star) {
      tongleStar(false);
    } else tongleStar(true);
  };

  return (
    <>
      <section
        className={styles.events__banner}
        style={{
          background: `url(${
            event?.profilePageHeaderBanner
              ? event?.profilePageHeaderBanner
              : banerAsia
          }) no-repeat`,
        }}
      >
        <img
          className={styles.events__banner__image}
          src={event?.logo ? event?.logo : asiaLogo}
          alt="logo"
          width="90"
          height="90"
        />
      </section>

      <section className={classNames("eventHeaderBg", styles.events__title)}>
        {event?.status
          ? event?.status && (
              <p
                className={classNames(
                  "eventStatusColorBg",
                  styles.events__title__life
                )}
              >
                {event?.status}
              </p>
            )
          : false}
        <h3 className={styles.events__title__name}>
          {event?.name ? event?.name : " EVENT NAME"}
        </h3>
        <article className={styles.events__date__and__place}>
          <img
            className={styles.events__date__image}
            src={calendar}
            alt="calendar"
            width="23"
            height="31"
          />
          {!!event?.startDate && (
            <>
              {fulldate(event?.startDate, event?.endDate)}
              <img
                className={styles.events__date__image}
                src={point}
                alt=""
                width="17.4"
                height="20"
              />
            </>
          )}
          {!!event?.location && (
            <p className={styles.events__date__text}>{event?.location}</p>
          )}
        </article>
        {/* TODO TEST */}
        <article className={styles.events__buttons__and__static}>
          <div
            className={classNames("eventHeaderButtonBg", styles.events__button)}
            onClick={() => goToUrl(`/community/${event?.community}/events`)}
          >
            <h1 className={styles.events__button__text}>
              {" "}
              <FormattedMessage id="navigation.community" />
            </h1>
          </div>{" "}
          {!!event?.type && (
            <p className={styles.events__date__text}>
              <FormattedMessage id="community.type" /> {event?.type}
            </p>
          )}
        </article>
        <article className={styles.events__heart__abs}>
          {isAuthenticated() ? (
            star === false ? (
              <div
                className={classNames(
                  "eventHeaderButtonBg",
                  styles.events__button__followed
                )}
                onClick={() => tongStar()}
              >
                <h1 className={styles.events__button__text}>
                  <FormattedMessage id="id.follow" />
                </h1>
              </div>
            ) : (
              <div
                className={classNames(
                  "eventHeaderButtonBg",
                  styles.events__button
                )}
                onClick={() => tongStar()}
              >
                <h1 className={styles.events__button__text}>unfolow</h1>
              </div>
            )
          ) : (
            <div
              className={classNames(
                "eventHeaderButtonBg",
                styles.events__button
              )}
            >
              <h1 className={styles.events__button__text}>
                <div onClick={() => goToUrl("/login")}>
                  <FormattedMessage id="global.buttons.login" />
                </div>
              </h1>
            </div>
          )}
        </article>
      </section>
    </>
  );
}
export default EventHeader;
