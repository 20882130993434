import { FormattedMessage } from "react-intl";

import ImagesComponent from "./components/images-row";
import TeamContactsComponent from "./components/team-contacts-row";

import banerAsia from "../../../../assets/images/event-asia-baner.png";
import GameItem from "./components/game-item/game-item";

import styles from "./styles.module.scss";

const EventBody = ({ event }) => {
  return (
    <div className={styles.events__about__wrapper}>
      <section className={styles.events__about}>
        <h4 className={styles.events__about__title}>
          {" "}
          <FormattedMessage id="tournaments.details.info.tabs.information" />
        </h4>
        <p className={styles.events__about__description}>
          {event?.description ? event?.description : false}
        </p>
        <p className={styles.events__about__description}>
          {/* <FormattedMessage id="match.ticker.layer" /> : */}
          Game about information lorem ipsum lorem ipsum lorem Game about
          information lorem ipsum lorem ipsum lorem Game about information lorem
          ipsum lorem ipsum lorem Game about information lorem ipsum lorem ipsum
          lorem
          {event?.grade ? event?.grade : false}
        </p>
        <div className={styles.events__about__data}>
          {event?.countries?.length ? (
            <>
              <p className={styles.about__data__title}>
                {" "}
                <FormattedMessage id="community.list.description.country " /> :
              </p>
              {event?.countries?.map((el) => (
                <p className={styles.about__data__description}>{el}</p>
              ))}
            </>
          ) : (
            false
          )}
        </div>

        {!!event?.games?.games?.length && (
          <>
            <h4 className={styles.events__about__title}>
              <FormattedMessage id="community.list.description.games" />
            </h4>
            <article
              className={styles.events__organizer__team}
              style={{ alignItems: "normal" }}
            >
              <div className={styles.events__games__container}>
                {event?.games?.games?.map((el) => (
                  <GameItem
                    title={el?.title ? el?.title : "Game"}
                    logo={el?.logo ? el?.logo : banerAsia}
                  />
                ))}
              </div>
            </article>
          </>
        )}
        {!!event?.organizerLogo?.length && (
          <>
            <h4 className={styles.events__about__title}>
              <FormattedMessage id="community.organizer" />
            </h4>
            <article
              className={styles.events__organizer}
              style={{ justifyContent: "space-around" }}
            >
              <h5 className={styles.organizer__card__title}>
                {event?.nameOfOrganizer}
              </h5>
              <div className={styles.organizer__card__box}>
                {event?.organizerLogo?.map((el) => (
                  <img
                    className={styles.organizer__image}
                    src={el}
                    alt=""
                    width="50px"
                    height="50px"
                  />
                ))}
              </div>
            </article>
          </>
        )}
        {!!event?.teamOfOrganizer?.length && (
          <TeamContactsComponent
            teamOfOrganizer={true}
            information={event?.teamOfOrganizer}
          />
        )}
        {!!event?.contacts?.length && (
          <TeamContactsComponent
            contacts={true}
            information={event?.contacts}
          />
        )}

        {/* {!!event?.partnerLogo?.length && ( */}
        <ImagesComponent partnerLogo={true} images={event?.partnerLogo} />
        {/* )} */}
        {!!event?.sponsorLogo?.length && (
          <ImagesComponent sponsorLogo={true} images={event?.sponsorLogo} />
        )}
        {!!event?.anyPhotos?.length && (
          <ImagesComponent anyPhotos={true} images={event?.anyPhotos} />
        )}
      </section>
    </div>
  );
};

export default EventBody;
