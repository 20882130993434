import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import { useSelector } from "react-redux";
// import { toast } from "react-toastify";

import store from "../../redux/store";
import {
  getUserData,
  setUserData,
  userOffline,
  userOnline,
} from "../../redux/actions";

import { API, API_ROUTER } from "../../api";

import InvitMessage from "../../components/invit-message";

import profLogo from "../../assets/images/bg.png";
import logo from "../../assets/images/passport-logo.png";

import Profile from "../../assets/images/bg.png";

// import PhotoIcon from "../../assets/svg/Photo_.svg";
import styles from "./styles.module.scss";

import arrow from "../../assets/icons/downarrow.svg";

export const FILTER_VALUE_SHOW_MY_MATCHES = "2";

const BurgerOpenList = ({ history, match, userData }) => {
  const [showList, setShowList] = useState(false);

  const [teams, setTeams] = useState([]);
  const [teamInvites, setTeamInvites] = useState([]);

  function goToUrl(url) {
    history.push(url);
    localStorage.setItem("matchFilter", FILTER_VALUE_SHOW_MY_MATCHES);
    localStorage.removeItem("matchFilterGame", FILTER_VALUE_SHOW_MY_MATCHES);
  }

  const logOut = (e) => {
    e.preventDefault();

    const params = {
      ...API_ROUTER.auth.logOut,
    };

    API.request(params, true)
      .then(() => {
        sessionStorage.removeItem("token");
        localStorage.removeItem("token");
        store.dispatch(userOffline());
        store.dispatch(setUserData(null));
        window.location.href = "/login";
      })
      .catch((err) => console.log(err));
  };

  const checkList = () => {
    if (showList) {
      setShowList(() => false);
    }
    setShowList(() => !showList);
  };

  const getUserTeams = () => {
    API.request({
      ...API_ROUTER.teams.getMyTeams,
    })
      .then((res) => {
        setTeams(res.teams);
      })
      .catch((err) => console.log(err));
  };

  const getTeamInvites = () => {
    API.request({
      ...API_ROUTER.teams.getTeamInvites,
    })
      .then((res) => {
        setTeamInvites(res.teams);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserData();
    getTeamInvites();
    getUserTeams();
  }, []);

  // const renderTeams = (teams) => {
  //   let teamsList = [];
  //   const createOption = [
  //     <div>
  //       {teamInvites?.map((el) => (
  //         <InvitMessage key={el.id} id={el.id} name={el.name} logo={el.logo} />
  //       ))}
  //       <Link
  //         className={styles.dropListLink}
  //         style={{ textDecoration: "none" }}
  //         to={`/teams/team/create`}
  //       >
  //         <FormattedMessage id="more.createNewTeam" />
  //       </Link>
  //     </div>,
  //   ];

  //   if (!teams.length) {
  //     teamsList.push(
  //       <div className={styles.emptyList}>
  //         <FormattedMessage id="more.noTeams" />
  //       </div>
  //     );
  //   } else {
  //     teamsList = teams.map((items) => (
  //       <li className={styles.dropListItem}>
  //         <Link
  //           style={{ textDecoration: "none" }}
  //           to={`/teams/team/${items.id}`}
  //         >
  //           {items.name}
  //         </Link>
  //       </li>
  //     ));
  //   }

  //   return [...createOption, ...teamsList];
  // };

  return (
    <div className={styles.menuWrapper}>
      <div className={styles.userInfo}>
        <div className={styles.profileWrapper}>
          <div className={styles.logoWrapper}>
            <img
              src={userData?.avatar ? userData?.avatar : Profile}
              alt="logo"
              className={styles.logo}
            />
          </div>
          <div className={styles.profileDescription}>
            <h3 className={styles.name}>{userData?.nickname || ""}</h3>
            <p className={styles.email}>{userData?.username || ""}</p>
          </div>
        </div>
      </div>

      <ul>
        <li className={styles.menuItem}>
          <Link style={{ textDecoration: "none" }} to={`/id/${userData?.url}`}>
            <FormattedMessage id="more.goToProfile" />
          </Link>
        </li>
        {/* <li>
          <button
            style={{ textDecoration: "none" }}
            onClick={() => checkList()}
            // " button-team-list"
          >
            <span className={styles.buttonTeamTitle}>
              <img
                className={` ${styles.titleArrow}
              ${!!showList && styles.titleArrow}`}
                width={10}
                height={10}
                src={arrow}
              />
              <FormattedMessage id="more.myTeams" />
            </span>
          </button>
          <ul
            className={` ${styles.listTeams}
              ${
                showList
                  ? styles.open
                  : styles.close
              }`}
          >
            {renderTeams(teams)}
          </ul>
        </li> */}

        <li className={styles.menuItem}>
          <Link style={{ textDecoration: "none" }} to="/calendar/tournaments">
            <FormattedMessage id="navigation.tournaments" />
          </Link>
        </li>
        <li className={styles.menuItem}>
          <FormattedMessage id="tournaments.details.info.tabs.brackets" />
        </li>
        <li className={styles.menuItem}>
          <Link style={{ textDecoration: "none" }} to="/watch">
            <FormattedMessage id="id.performance.game.matches" />
          </Link>
        </li>
        <li className={styles.menuItem}>
          <p onClick={() => goToUrl("/watch")}>Play</p>
        </li>
        <li className={styles.menuItem}>
          <p onClick={() => history.push("/followed/tournaments")}>Watch</p>
        </li>

        <li className={styles.menuItem}>
          <a
            href="https://about.passport.gg/gamersupport"
            target={"_blank"}
            style={{ textDecoration: "none" }}
          >
            <FormattedMessage id="footer.support" />
          </a>
        </li>
        <li className={styles.menuItem}>
          <button className={styles.button} onClick={logOut}>
            <FormattedMessage id="more.disconnect" />
          </button>
        </li>
      </ul>
      <div className={styles.footer}>
        <p>
          <Link to="/terms-of-use">
            <FormattedMessage id="more.termsOfUse" />
          </Link>
          &nbsp;&nbsp;
          <Link to="/privacy-policy">
            <FormattedMessage id="more.privacyPolicy" />
          </Link>
          &nbsp;
          <Link to="/cookie-policy">
            <FormattedMessage id="more.cookiesPolicy" />
          </Link>
        </p>
      </div>
      {/* <a
          className="open-list__pre-footer"
          href="http://app.passport.gg/"
          target={"_blank"}
          style={{ textDecoration: "none" }}
        >
          Powered by &nbsp;&nbsp; &nbsp;&nbsp;
          <img src={logo} width="150px" />
        </a> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userOnline: state.userOnline,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    runGetUserData: () => dispatch(getUserData()),
    runUserOnline: () => dispatch(userOnline()),
  };
};

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(BurgerOpenList))
);
