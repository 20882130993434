import React from "react";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";

import styles from "./styles.module.scss";

export default function CupTab({ navList, showAll = true }) {
  const history = useHistory();
  const { pathname } = useLocation();

  function goToUrl(url) {
    history.push(url);
  }

  return (
    <div className={classNames("cupTabBorder", "b-shadow", styles.container)}>
      <ul className={styles.list}>
        {navList.map((i) => (
          <>
            {(showAll || (!showAll && i.isShow)) && (
              <li
                key={`cup-tab-${i.id}`}
                onClick={() => goToUrl(i.to)}
                className={classNames(
                  {
                    [styles.active]:
                      i.to === pathname ||
                      i.to1 === pathname ||
                      i.to2 === pathname,
                  },
                  styles.listItem
                )}
              >
                {i.title}
              </li>
            )}
          </>
        ))}
      </ul>
    </div>
  );
}
