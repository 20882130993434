import { FormattedMessage } from "react-intl";

import styles from "./styles.module.scss";

const TeamContactsComponent = ({ teamOfOrganizer, contacts, information }) => {
  console.log(information);
  return (
    <>
      <h4 className={styles.events__about__title}>
        {!!teamOfOrganizer && <FormattedMessage id="id.teams.title" />}
        {!!contacts && (
          <FormattedMessage id="community.list.description.contacts" />
        )}
      </h4>
      <article className={styles.events__organizer__team}>
        {information?.map((el) => (
          <div className={styles.events__organizer__team__container}>
            <h1 className={styles.organizer__card__title}>
              {el?.name ? el?.name : "name"} :
            </h1>
            <div className={styles.organizer__card}>
              <p className={styles.organizer__card__title}>
                {el?.title ? el?.title : "title"}
              </p>
            </div>
          </div>
        ))}
      </article>
    </>
  );
};

export default TeamContactsComponent;
