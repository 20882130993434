import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Pagination } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { isAuthenticated } from "../../../../helpers";
import { LinearProgress } from "@material-ui/core";
import { API, API_ROUTER } from "../../../../api";

import TournamentItem from "../../../../components/list-items/components/tournamentItem";
import styles from "./styles.module.scss";
// стили пагинации
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#fff",
    },
  },
}));

const EventFooter = ({ eventId }) => {
  const [tournaments, setTournaments] = useState();
  const [pagination, setPagination] = useState(1);
  const [page, setPage] = useState(1);
  const [requestSuccess, setRequestSuccess] = useState(false);

  const classes = useStyles();
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    isAuthenticated()
      ? API.request({
          ...API_ROUTER.events.getEventTournamentsAuth,
          pathKeys: {
            eventId: eventId,
            page: page ? page : 1,
            limit: 10,
          },
        })
          .then((res) => {
            setTournaments(res.tournaments);
            setPagination(res.paginator);
            setRequestSuccess(true);
          })
          .catch((err) => console.log(err))
      : API.request({
          ...API_ROUTER.events.getEventTournaments,
          pathKeys: {
            eventId: eventId,
            page: page ? page : 1,
            limit: 10,
          },
        })
          .then((res) => {
            setTournaments(res.tournaments);
            setPagination(res.paginator);
            setRequestSuccess(true);
          })
          .catch((err) => console.log(err));
  }, [page]);

  const renderTournaments = (tournaments) => {
    if (!tournaments.length)
      return (
        <div>
          <FormattedMessage id="community.tournaments.noMore" />
        </div>
      );
    return (
      <article>
        <ul className={styles.event__list__events}>
          {tournaments.map((items) => (
            <TournamentItem {...items} key={items.uuid} />
          ))}
          {(tournaments?.length > 9) | pagination?.hasNextPage ? (
            <div className={styles.notification__pagination}>
              <Pagination
                classes={{ ul: classes.ul }}
                count={pagination?.lastPage}
                page={pagination?.currentPage}
                onChange={handleChange}
                variant="outlined"
              />
            </div>
          ) : (
            false
          )}
        </ul>
      </article>
    );
  };
  return (
    <section className={styles.events__tournaments}>
      <h3 className={styles.events__about__title}>
        <FormattedMessage id="calendar.tournaments" />
      </h3>
      <section className={styles.events__cards}>
        <>
          {requestSuccess ? renderTournaments(tournaments) : <LinearProgress />}
        </>
      </section>
    </section>
  );
};

export default EventFooter;
