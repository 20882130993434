import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import { API, API_ROUTER } from "../../api";
import { MatchItem } from "../matches/components";

export default function CupMatches(props) {
  const [matches, setMatches] = useState();
  let { id } = useParams();

  const getTournamentMatches = () => {
    API.request({
      ...API_ROUTER.tournaments.getTournamentMatches,
      pathKeys: {
        tournamentId: id,
      },
    })
      .then((res) => {
        setMatches(res.matches);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTournamentMatches();
  }, []);

  const renderMatches = () => {
    return (
      <div className="matches-wrapper">
        {matches?.map((el) => (
          <MatchItem
            key={el.matchId}
            name={el?.name}
            tournamentLogo={el.tournament.logo}
            tournamentName={el.tournament.name}
            nameGame={el.game.name}
            gameLogo={el.game.logo}
            tournamentMember1={el.tournamentMember1 || 0}
            tournamentMember2={el.tournamentMember2 || 0}
            tournamentMemberScore1={el.tournamentMemberScore1}
            tournamentMemberScore2={el.tournamentMemberScore2}
            mode={el?.mode}
            groupsName={el.round.name}
            startedAt={el.startedAt}
            matchId={el.matchId}
            status={el.status}
            tournamentStageType={el.tournamentStageType}
            game={el.game}
            round={el.round}
          />
        ))}
      </div>
    );
  };

  return (
    <StyledCupMatches>
      <div className="wrapper">
        {renderMatches()}
        {/* TODO comment filter */}
        {/* <Calendar />
        <section className="result__hide">
          <p>
            <FormattedMessage id="battlecup.matches.hideResult" />
          </p>
          <Switch />
        </section> */}

        {/* <section className="holding-tournaments__filters">
          <ul>
            <li className="active">
              <FormattedMessage id="battlecup.matches.allow" />
            </li>
            <li className="">
              <i className="icon icon-star" />
              <p>
                <FormattedMessage id="battlecup.matches.follow" />
              </p>
            </li>
            <li>
              <FormattedMessage id="battlecup.matches.live" />
            </li>
            <li>
              <FormattedMessage id="battlecup.matches.soon" />
            </li>
            <li>
              <FormattedMessage id="battlecup.matches.ended" />
            </li>
          </ul>
          <input
            className="holding-tournaments__filter"
            type="radio"
            name="holding-tournaments-filter"
            id="tournaments-filter-1"
            value="all"
            defaultChecked
          />
          <label
            className="holding-tournaments__filter-label"
            htmlFor="tournaments-filter-1"
          >
            All
          </label>
          <input
            className="holding-tournaments__filter"
            type="radio"
            name="holding-tournaments-filter"
            id="tournaments-filter-2"
            value="star"
          />
          <label
            className="holding-tournaments__filter-label"
            htmlFor="tournaments-filter-2"
          >
            <img src={star} alt="Star" width="10" height="10" />
          </label>
          <input
            className="holding-tournaments__filter"
            type="radio"
            name="holding-tournaments-filter"
            id="tournaments-filter-3"
            value="live"
          />
          <label
            className="holding-tournaments__filter-label"
            htmlFor="tournaments-filter-3"
          >
            Live
          </label>
          <input
            className="holding-tournaments__filter"
            type="radio"
            name="holding-tournaments-filter"
            id="tournaments-filter-4"
            value="soon"
          />
          <label
            className="holding-tournaments__filter-label"
            htmlFor="tournaments-filter-4"
          >
            Soon
          </label>
          <input
            className="holding-tournaments__filter"
            type="radio"
            name="holding-tournaments-filter"
            id="tournaments-filter-5"
            value="ended"
          />
          <label
            className="holding-tournaments__filter-label"
            htmlFor="tournaments-filter-5"
          >
            Ended
          </label>
        </section> */}

        <section className="game__list"></section>

        {!matches?.length && (
          <p className="no__match">
            <FormattedMessage id="battlecup.matches.noMore" />
          </p>
        )}
      </div>
    </StyledCupMatches>
  );
}

const StyledCupMatches = styled.div`
  padding: 25px 18px;

  .matches-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & > .wrapper {
    border-top: 1px solid #939598;

    & > section {
      &.result__hide {
        background-color: #414042;
        padding: 14px 18px;
        margin-top: 12px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        & > p {
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
        }
      }

      &.filter__tabs {
        margin-top: 18px;

        & > ul {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          gap: 12px;

          & > li {
            padding: 5px 15px;
            min-width: 60px;
            text-transform: uppercase;
            color: #6d6e71;
            font-size: 9px;
            font-style: normal;
            letter-spacing: -0.36px;
            line-height: normal;
            border: 1px solid var(--white);
            border-radius: 12px;
            cursor: pointer;
            text-align: center;

            &:nth-child(2) {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              gap: 6px;

              i {
                width: 8px;
                height: 8px;
                background-color: #6d6e71;
              }
            }

            & > * {
              color: #6d6e71;
            }

            &.active {
              color: var(--dark);
              background-color: #bcbec0;

              & > p {
                color: var(--dark);
              }

              & > i {
                background-color: var(--dark);
              }
            }
          }
        }

        @media (max-width: 450px) {
          ul {
            display: -ms-grid;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
          }
        }
      }

      &.game__list {
        margin-top: 20px;
      }
    }

    & > .no__match {
      text-transform: uppercase;
      color: #6d6e71;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: -0.61px;
      line-height: normal;
      text-align: center;
    }

    & > .register__btn__wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-top: 60px;
    }
  }
`;
