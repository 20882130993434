import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { useDraggable } from "react-use-draggable-scroll";
import { toast } from "react-toastify";
import GameSwitcher from "../../../components/game-switcher";
import { CheckBox, Preloader } from "../../../components/UI";
import noImage from "../../../assets/images/no-image.webp";

import styles from "./styles.module.scss";

const RatingTable = ({
  ratingData,
  onGameChange,
  games,
  selectedGame,
  isLoad,
}) => {
  const history = useHistory();
  const dragRef = useRef();
  const { events } = useDraggable(dragRef);

  // если участник - команда, то выводим название команды, если нет, то никнейм
  const membersData = (data) => {
    if (data?.teamId === null) {
      return (
        <td
          onClick={(e) => goToUrl(`/id/${data?.userUrl}`)}
          className={styles.name}
        >
          <div className={styles.logo}>
            <img src={data?.userAvatar ? data?.userAvatar : noImage} />
          </div>
          {data?.userNickname ? data?.userNickname : "User"}
        </td>
      );
    } else if (data?.userId === null) {
      return (
        <td
          onClick={(e) => goToUrl(`/teams/team/${data?.teamId}`)}
          className={styles.name}
        >
          <div className={styles.logo}>
            <img src={data?.teamLogo ? data?.teamLogo : noImage} />
          </div>
          {data?.teamName ? data?.teamName : "Team"}
        </td>
      );
    } else {
      return (
        <td className={classNames("bracketFFANameHover", styles.name)}>
          <span className={styles.logo}>
            <img src={noImage} />
          </span>
          name
        </td>
      );
    }
  };
  const goToUrl = (url) => {
    history.push(url);
  };

  return (
    <>
      <div
        {...events}
        ref={dragRef}
        className={classNames("matchMembersWrapperBg", styles.detailWrap)}
      >
        {isLoad && (
          <div
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            <Preloader />
          </div>
        )}
        {!isLoad && (
          <div>
            <div
              style={{ padding: "10px", background: "rgb(255 255 255 / 10%)" }}
            >
              <GameSwitcher
                selectedGame={
                  selectedGame ? (
                    selectedGame
                  ) : (
                    <FormattedMessage id="settings.games.chooseGame" />
                  )
                }
                gamesAvailableObj={games}
                setSelectedGameObj={onGameChange}
              />
            </div>
            <div className={styles.scrollWrap}>
              <div className={styles.tableWrap}>
                <table className={styles.table}>
                  <caption>MATCH MEMBERS FFA</caption>
                  <colgroup>
                    <col width="6.7%" />
                    <col width="*" />
                    <col width="12%" />
                    <col width="17.5%" />
                    <col width="12%" />
                    <col width="17.5%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col" colSpan={2}>
                        USER/TEAM
                      </th>
                      <th scope="col">PTS</th>
                      <th scope="col">WINS</th>
                      <th scope="col">DEFEATS</th>
                      <th scope="col">WINRATE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ratingData?.reverse().map((el, index) => (
                      <tr key={index}>
                        <td></td>

                        {membersData(el)}
                        <td>{el?.total}</td>
                        <td>{el?.win}</td>
                        <td>{el?.lose}</td>
                        <td>
                          {Math.floor((el?.win / el?.matches) * 100) || 0}%
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RatingTable;
