import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import classNames from "classnames";
import { Pagination } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@mui/material/TablePagination";

import { API, API_ROUTER } from "../../api";

import { ArrowButton, CheckBox, RangePicker } from "../../components/UI";
import { ContentBox, Container, Select } from "../../components/UI";
import GameSwitcher from "../../components/game-switcher";

import placeHolder from "../../assets/fake-team.png";
import country from "../../assets/images/flags/Hungary.png";
import verefied from "../../assets/icons/verefied.svg";
import RatingTable from "./table";
import "./LeaderBoard.css";
import Styled from "./style";
import styles from "./styles.module.scss";

const LeaderBoard = () => {
  const [participants, setParticipants] = useState();
  const [filter, setFilter] = useState("tekken");
  const [selectedGame, setSelectedGame] = useState(
    <FormattedMessage id="settings.games.chooseGame" />
  );
  const [games, setGames] = useState([]);
  const [ratingPage, setRatingPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const history = useHistory();
  const onGameChange = (game) => {
    setSelectedGame(game);
    setRatingPage(1);
  };
  const [isLoad, setIsLoad] = useState(false);

  // пагинация
  const pageChange = (event, value) => {
    setRatingPage(value);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setRatingPage(0);
  };
  // массив с участниками
  useEffect(() => {
    setIsLoad(true);
    API.request({
      ...API_ROUTER.rating.getRatingTable,
      pathKeys: {
        page: ratingPage ? ratingPage : 1,
        limit: rowsPerPage ? rowsPerPage : 100,
      },
      // фильтр по игре
      data: {
        gameId: selectedGame?.id || "",
      },
    })
      .then((res) => {
        setParticipants(res);
        setIsLoad(false);
      })
      .catch((err) => console.log(err));
  }, [selectedGame, ratingPage, rowsPerPage]);
  // массив с играми
  useEffect(() => {
    API.request({
      ...API_ROUTER.games.getGames,
    })
      .then((res) => {
        setGames(res.games);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="rating__container">
      <Styled.RatingCol style={{ cursor: "not-allowed" }}>
        <Styled.StyledContentBox>
          <Styled.FilterTitle>
            <FormattedMessage id="ratings.addFilters" tagName="span" />
          </Styled.FilterTitle>
          <Styled.FilterBox>
            <div className="filter__raiting-title">
              <FormattedMessage id="ratings.geo" />
            </div>
            <div className="form-group">
              <FormattedMessage
                id="global.forms.labels.country"
                tagName="label"
              />
              <Select clearable />
            </div>
            <div className="form-group">
              <FormattedMessage
                id="global.forms.labels.region"
                tagName="label"
              />
              <Select clearable />
            </div>
            <div className="form-group">
              <FormattedMessage id="global.forms.labels.city" tagName="label" />
              <Select clearable />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="global.forms.labels.distance" />:{" "}
              </label>
              <RangePicker
                hideLeftHandler
                min={0}
                max={100}
                step={1}
                onChange={(value) => {}}
              />
            </div>
            )
          </Styled.FilterBox>
          <Styled.FilterBox>
            <div className="filter__raiting-title">
              <FormattedMessage id="ratings.demography" />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="global.forms.labels.age" />:{" "}
              </label>
              <RangePicker
                min={0}
                max={100}
                step={1}
                onChange={(value) => {}}
              />
            </div>
            <div className="form-group">
              <FormattedMessage
                id="global.forms.labels.language"
                tagName="label"
              />
              <Select clearable />
            </div>
            <div className="form-group">
              <FormattedMessage
                id="global.forms.labels.gender"
                tagName="label"
              />
              <Select clearable />
            </div>
          </Styled.FilterBox>
          <Styled.FilterBox>
            <div className="filter__raiting-title">
              <FormattedMessage id="ratings.type" />
            </div>
            <div className="form-group">
              <FormattedMessage
                id="global.forms.labels.progamer"
                tagName="label"
              />
              <Select clearable />
            </div>
            <div className="form-group">
              <FormattedMessage
                id="global.forms.labels.streaming"
                tagName="label"
              />
              <Select clearable />
            </div>
          </Styled.FilterBox>
          <Styled.FilterBox>
            <ArrowButton
              label={<FormattedMessage id="ratings.clearFilters" />}
              variant="secondary"
            />
          </Styled.FilterBox>
        </Styled.StyledContentBox>
      </Styled.RatingCol>
      <section
        className={classNames(
          // "leaderbordBg",
          "leaderbord"
        )}
      >
        <form action="" className="leaderbord__form">
          {/* <div className="leaderbord__form-competition-rank">
            <input
              className="form-competition-rank__input"
              type="radio"
              name="competition-rank"
              value="regional"
              id="competition-rank__regional"
            />
            <label
              className="form-competition-rank__label"
              htmlFor="competition-rank__regional"
            >
              regional
            </label>
            <input
              className="form-competition-rank__input"
              type="radio"
              name="competition-rank"
              value="national"
              id="competition-rank__national"
            />
            <label
              className="form-competition-rank__label"
              htmlFor="competition-rank__national"
            >
              national
            </label>
            <input
              className="form-competition-rank__input"
              type="radio"
              name="competition-rank"
              value="continental"
              id="competition-rank__continental"
            />
            <label
              className="form-competition-rank__label"
              htmlFor="competition-rank__continental"
            >
              continental
            </label>
          </div> */}
          {/* <ul className="leaderbord__form-game-list">
            <li className="leaderbord__form-game">
              <input
                className="form-game__input"
                type="radio"
                id="form-game__pubg"
                name="form-game"
                value="pubg"
              />
              <label className="form-game__label" htmlFor="form-game__pubg">
                PUBG
              </label>
            </li>
            <li className="leaderbord__form-game">
              <input
                className="form-game__input"
                type="radio"
                id="form-game__fifa"
                name="form-game"
                value="fifa"
              />
              <label className="form-game__label" htmlFor="form-game__fifa">
                FIFA
              </label>
            </li>
            <li className="leaderbord__form-game">
              <input
                className="form-game__input"
                type="radio"
                id="form-game__tekken"
                name="form-game"
                value="tekken"
                defaultChecked
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
              <label className="form-game__label" htmlFor="form-game__tekken">
                TEKKEN 7
              </label>
            </li>
            <li className="leaderbord__form-game">
              <input
                className="form-game__input"
                type="radio"
                id="form-game__guilty-gear"
                name="form-game"
                value="guilty gear"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
              <label
                className="form-game__label"
                htmlFor="form-game__guilty-gear"
              >
                Rocket League
              </label>
            </li>
          </ul> */}
        </form>
        <div>
          <RatingTable
            ratingData={participants?.rows}
            onGameChange={onGameChange}
            games={games}
            selectedGame={selectedGame?.title}
            isLoad={isLoad}
          />
          <div className={styles.paginatorWrapper}>
            {/* <Pagination
              classes={{ ul: classes.ul }}
              count={participants?.paginator?.lastPage}
              page={participants?.paginator?.currentPage}
              onChange={pageChange}
              variant="outlined"
            /> */}
            <TablePagination
              component="div"
              sx={{
                borderRadius: "10px",
                ".MuiTablePagination-toolbar": {
                  backgroundColor: "#424243 ",
                },
              }}
              SelectProps={{
                MenuProps: {
                  sx: {
                    ".MuiPaper-root": {
                      backgroundColor: "#424243 ",
                    },
                  },
                },
              }}
              classes={{ div: styles.pagination }}
              count={participants?.paginator?.totalResults || 0}
              page={participants?.paginator?.currentPage || 0}
              onPageChange={pageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default LeaderBoard;
