import { useState, useEffect } from "react";
import { LinearProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { API, API_ROUTER } from "../../api";

import EventHeader from "./sections/header";
import EventBody from "./sections/body";
import EventFooter from "./sections/footer";

import Wrapper from "../../components/wrapper";
import GlobalStyles from "../../assets/styles/global";

import EventDoesnotExistModal from "./modal";

export default function GameWiki() {
  const [event, setEvent] = useState({});
  const [eventDoesNotExist, setEventDoesNotExist] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [follow, tongleFollow] = useState();

  const userData = useSelector((state) => state.userData);

  // get params event id
  let { id } = useParams();

  // get event info
  useEffect(() => {
    API.request({
      ...API_ROUTER.events.getCurrentEvent,
      pathKeys: {
        eventId: id,
      },
    })
      .then((res) => {
        setEvent(res);
        // follow state
        tongleFollow(res.follow);
        setRequestSuccess(true);
      })
      .catch((err) =>
        // if event id undefined show modal error
        setEventDoesNotExist(!!err?.data?.errors?.eventId && true)
      );
  }, []);

  return (
    <>
      {/* {!!requestSuccess && ( */}
      <>
        <GlobalStyles />
        <Wrapper>
          <EventHeader />
          <EventBody />
          <EventFooter gameId={id} />
        </Wrapper>
      </>
      {/* )} */}
      {/* {!requestSuccess && (
        <>
          <LinearProgress />
        </>
      )} */}
    </>
  );
}
