import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";

import { API, API_ROUTER } from "../../../../api";
import { isAuthenticated } from "../../../../helpers";

import styles from "./styles.module.scss";
import AsiaLogo from "../../../../assets/images/IESFBigBangAsia.png";
import starImage from "../../../../assets/svg/Star.svg";
import starGoldImage from "../../../../assets/svg/StarGold.svg";

const UserItem = ({ id, nickname, avatar, url, pro, verefied }) => {
  const history = useHistory();

  const [star, tongleStar] = useState(true);

  const followHandler = () => {
    API.request({
      ...API_ROUTER.follow.IsUserFollowUser,
      pathKeys: {
        favoriteId: id,
      },
    })
      .then((res) =>
        res.follow === true
          ? API.request({
              ...API_ROUTER.follow.stopFollowUser,
              pathKeys: {
                favoriteId: id,
              },
            })
              .then((res) => {
                toast.success(res.message);
              })
              .catch((err) => console.log(err))
          : API.request({
              ...API_ROUTER.follow.followUser,
              data: {
                favoriteId: id,
              },
            })
              .then((res) => {
                toast.success(res);
              })
              .catch((err) => console.log(err))
      )
      .catch((err) => console.log(err));

    if (star) {
      tongleStar(false);
    } else tongleStar(true);
  };
  function goToUrl(url) {
    history.push(url);
  }
  return (
    <li
      className={classNames("calendarItemBorder", styles.user)}
      style={{}}
      key={id}
    >
      <img
        src={avatar ? avatar : AsiaLogo}
        className={styles.userLogo}
        onClick={() => goToUrl(`/id/${url}`)}
      />
      <div
        className={styles.userDescription}
        onClick={() => goToUrl(`/id/${url}`)}
      >
        <h4 className={styles.userName}>{nickname ? nickname : "nickname"}</h4>
        <div className={styles.userDecoration} />
        <p className={styles.userDate}>user bio</p>
      </div>
      {!!isAuthenticated() && (
        <img
          // If followed Golden else Default
          src={star ? starImage : starGoldImage}
          className={styles.user__star}
          onClick={() => followHandler()}
        />
      )}
    </li>
  );
};

export default UserItem;
