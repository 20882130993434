import { FormattedMessage } from "react-intl";
import React from "react";
import { v4 as uuidv4 } from "uuid";

export function getGameCards(games = [], history, isCreator) {
  let gameCard;
  if (isCreator) {
    return (gameCard = [
      ...games,
      {
        id: uuidv4(),
        add: true,
        title: <FormattedMessage id="id.games.add" />,
        action: () => {
          history.push("/games");
        },
        img: "",
      },
    ]);
  }
  if (!isCreator) {
    return (gameCard = [...games]);
  }
  return gameCard;
}

export function getTeamCard(teams = [], history, isCreator) {
  let teamCard;
  if (isCreator) {
    return (teamCard = [
      ...teams,
      {
        id: uuidv4(),
        add: true,
        title: <FormattedMessage id="id.teams.createTeam" />,
        action: () => {
          history.push("/teams/team/create");
        },
        img: "",
      },
    ]);
  }
  if (!isCreator) {
    return (teamCard = [...teams]);
  }
  return teamCard;
}
export function getTournamentCard(teams = [], history, isCreator) {
  let teamCard;
  if (isCreator) {
    return (teamCard = [
      ...teams,
      {
        id: uuidv4(),
        add: true,
        title: <FormattedMessage id="cityBattle.registration.title" />,
        action: () => {
          history.push("/calendar/tournaments");
        },
        img: "",
      },
    ]);
  }
  if (!isCreator) {
    return (teamCard = [...teams]);
  }
  return teamCard;
}
