import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { API, API_ROUTER } from "../../../../api";

import placeHolder from "../../../../assets/fake-team.png";

import styles from "./styles.module.scss";

export default function Card({
  add = false,
  title,
  isComing = false,
  game,
  logo,
  action,
  nickname,
  id,
}) {
  const [deleteGame, setdeleteGame] = useState(false);
  const [ifDeleted, setIfDeleted] = useState(false);

  // delete game handler
  const deleteGameHandler = () => {
    API.request({
      ...API_ROUTER.user.deleteUserGame,
      pathKeys: {
        gameId: game?.id,
      },
    })
      .then((res) => {
        toast.success("You have successfully delete the game");
        // set the game deleted
        setIfDeleted(true);
      })
      .catch((err) => {
        toast.error(err.data && err.data.message);
      });
  };

  const imageWithPlaceHolder = (game, logo) =>
    game?.image ? (
      <img
        // set show delete button
        onClick={() => setdeleteGame(!deleteGame)}
        className={styles.styledCard__image}
        src={game?.image ? game?.image : placeHolder}
        alt="img"
      />
    ) : (
      <img
        className={styles.styledCard__image}
        src={logo ? logo : placeHolder}
        alt="img"
      />
    );

  const NameOfCard = (value) => {
    return <p>{value.length > 15 ? value.slice(0, 15) + "..." : value}</p>;
  };

  return (
    // show games ( if no deleted )
    !ifDeleted && (
      <div className={styles.styledCard} key={game?.id}>
        {!!deleteGame && (
          <section
            onClick={() => deleteGameHandler()}
            className={styles.styledCard__header__delete}
          >
            DELETE
          </section>
        )}

        <section
          className={styles.styledCard__header}
          onClick={() => {
            !!action && action();
          }}
        >
          {/* if add place PLUS else place image */}

          {add ? (
            <i
              className="icon icon-plus"
              style={{ background: "var(--icon)" }}
            />
          ) : (
            imageWithPlaceHolder(game, logo)
          )}
        </section>
        <section className={styles.styledCard__footer}>
          {/* Main user */}
          {!!game?.id && NameOfCard(nickname ? nickname : "")}
          {!!game?.id && NameOfCard(game?.title ? game?.title : "")}
          {/* Public User */}
          {!game?.id && NameOfCard(nickname ? nickname : "")}
          {!game?.id && NameOfCard(game ? game : "")}

          {!!title && NameOfCard(title)}
        </section>
      </div>
    )
  );
}
