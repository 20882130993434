import { createGlobalStyle } from "styled-components";

import "normalize.css";
import "./var.css";
import "./icon.css";
import "../fonts/stylesheet.css";
import hogasiaModalLogo from "../images/logoHogasia.jpg";
import passportModalLogo from "../logo-sm.png";
import bigbangLogo from "../../assets/images/IESFBigBangAsia.png";

const PassportStyles = createGlobalStyle`

div,
p,
img,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
ul,
ol {
  list-style: none;
}

p {
  margin: 0;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "monsterrat", sans-serif;
  color: var(--white);
}
.c-p {
  cursor: pointer;
}

.b-shadow {
  box-shadow: 0 12px 13px 0px #00000075;
}
    body {
      margin: 0;
   padding: 0;
   font-family: "Monsterrat", "Oxygen", sans-serif;
   font-size: 14px;
   line-height: 20px;
   font-weight: 300;

   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscal;
        font-size: 16px;
        background: #3f317c;
      
    }
    .modalBg {

  background: rgb(46, 25, 65);
  background: linear-gradient(
    90deg,
    rgba(46, 25, 65, 1) 0%,
    rgba(53, 49, 124, 1) 52%
  );
  .modalTopBg{
    background: #1f193f;
   }

  .modalLogo{
    content: url(${passportModalLogo});

   }


}

`;
const HogasiaStyles = createGlobalStyle`
div,
p,
img,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
ul,
ol {
  list-style: none;
}

p {
  margin: 0;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "monsterrat", sans-serif;
  color: var(--white);
}
.c-p {
  cursor: pointer;
}

.b-shadow {
  box-shadow: 0 12px 13px 0px #00000075;
}

    body {
    margin: 0;
    padding: 0;
    font-family: "Monsterrat", "Oxygen", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    background-color: #262626;
    }
    .modalBg {
   background: rgb(38, 34, 35);
   background: linear-gradient(
     110deg,
    rgba(38, 34, 35, 1) 0%,
    rgba(191, 140, 44, 1) 100%
   );
   .modalTopBg{
    background:  #262626;
   
   }

   .modalLogo{
    content: url(${hogasiaModalLogo});

   }
}

`;
const BigBangStyles = createGlobalStyle`
div,
p,
img,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
ul,
ol {
  list-style: none;
}

p {
  margin: 0;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "monsterrat", sans-serif;
  color: var(--white);
}
.c-p {
  cursor: pointer;
}

.b-shadow {
  box-shadow: 0 12px 13px 0px #00000075;
}

    body {
    margin: 0;
    padding: 0;
    font-family: "Monsterrat", "Oxygen", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    background: rgba(88, 88, 89, 1);
    letter-spacing: 0.03em;
    }
    .modalBg {
      background: radial-gradient(
      104.65% 533.13% at 0.94% 53.84%,
      rgb(202, 45, 74) 0%,
      rgb(102, 16, 36) 44.13%,
      rgb(202, 45, 74) 76.01%,
      rgb(102, 16, 36) 100%
    );
   .modalTopBg{
    background: radial-gradient(
      104.65% 533.13% at 0.94% 53.84%,
      rgb(202, 45, 74) 0%,
      rgb(102, 16, 36) 44.13%,
      rgb(202, 45, 74) 76.01%,
      rgb(102, 16, 36) 100%
    );
   
   }

   .modalLogo{
    content: url(${bigbangLogo});

   }
}

`;
let style = "bigbang";

if (window.location.hostname === "app.passport.gg") {
  style = "passport";
} else if (window.location.hostname === "app.hogasia.com") {
  style = "hogasia";
} else if (window.location.hostname === "bigbang.passport.gg") {
  style = "bigbang";
}

let GlobalStyles;

switch (style) {
  case "hogasia":
    GlobalStyles = HogasiaStyles;
    break;
  case "passport":
    GlobalStyles = PassportStyles;
    break;
  case "bigbang":
    GlobalStyles = BigBangStyles;
    break;
  default:
}
export default GlobalStyles;
