export function convertDate(startetd, ended) {
  const start = new Date(startetd);
  const end = new Date(ended);
  const months = [
    "JAN",
    "FEB",
    "MARC",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEPT",
    "OCT",
    "NOV",
    "DEC",
  ];
  const dateStart = start.getDate();
  const monthNameStart = months[start.getMonth()];
  const YearNameStart = start.getFullYear();
  const startFullDate = [dateStart, " ", monthNameStart, " ", YearNameStart];
  const dateEnd = end.getDate();
  const monthNameEnd = months[end.getMonth()];
  const YearNameEnd = end.getFullYear();
  const endFullDate = [dateEnd, " ", monthNameEnd, " ", YearNameEnd];
  return startFullDate.join("") + "-" + endFullDate.join("");
}
