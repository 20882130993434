import { SET_LANGUAGES } from "../../types";

const rd = (state = [], action) => {
  switch (action.type) {
    case SET_LANGUAGES:
      return (state = action.payload);
    default:
      return state;
  }
};

export default rd;
