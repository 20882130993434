import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";

import "./Games.css";
import NoImage from "../../assets/no-image.png";
import { useDispatch, useSelector } from "react-redux";
import loadGames from "../../helpers/games/loadGames";
import addGame from "../../helpers/games/addGame";
import { toast } from "react-toastify";
import { API, API_ROUTER } from "../../api";
import { FormattedMessage, injectIntl } from "react-intl";

const Games = (props) => {
  const { intl } = props;
  const { style } = props;
  const [selectedGame, changeGameDiscipline] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [games, setGames] = useState([]);
  const [nickInput, setNickInput] = useState("");
  const [gamesMoba, setGamesMoba] = useState([]);
  const [gamesFPS, setGamesFPS] = useState([]);
  const [gamesOther, setGamesOther] = useState([]);
  const [gamesSport, setGamesSport] = useState([]);
  const [gamesFight, setGamesFight] = useState([]);
  const [gamesBattle, setGamesBattle] = useState([]);

  const history = useHistory();

  const user = useSelector((state) => state.userData);

  // const changeGame = () => {
  //   addGame(selectedGame)
  //     .then((res) => {
  //       toast.success("Game successfully added");
  //       history.push(`/id/${user.url}`);
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //       toast.error(e.message);
  //     });
  // };
  function addGame() {
    const data = {
      gameId: selectedGame.id,
      nickname: nickInput,
    };
    API.request({
      ...API_ROUTER.games.addGameToUserGames,
      data,
    })
      .then(() => {
        toast.success("Game successfully added");
        history.push(`/id/${user.url}`);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    API.request({
      ...API_ROUTER.games.getGames,
    })
      .then((res) => {
        // TODO BIGBANG put 5 games in the game list
        if (style === "bigbang") {
          res = {
            games: [
              {
                id: "01G2CHY9GXAQN51MP3BXW8HE69",
                logo: "https://storage-passport-gg.s3.eu-central-1.amazonaws.com/gameLogo/01G2CHY9GXAQN51MP3BXW8HE69/01GBGEX4MZ5R48F996BCWB9EGG/original?v=1661631107",
                status: "active",
                groupName: "BATTLE ROYALE",
                title: "PUBG Mobile",
              },
              {
                id: "01G2CHY9GWJGXBJ4XAS449XSZ1",
                logo: "https://storage-passport-gg.s3.eu-central-1.amazonaws.com/gameLogo/01G2CHY9GWJGXBJ4XAS449XSZ1/mm-GDRX121J2K1FJBJXR76QJSX/original?v=1666909635",
                status: "active",
                groupName: "SPORTS SIMULATORS",
                title: "Real Cricket",
              },
              {
                id: "01G2CHY9GWJGXBJ4XAS449XSZE",
                logo: "https://storage-passport-gg.s3.eu-central-1.amazonaws.com/gameLogo/01G2CHY9GWJGXBJ4XAS449XSZE/01GBGENSA04W4AEPMPADJ7AERP/original?v=1661630866",
                status: "active",
                groupName: "OTHER GAMES",
                title: "Dota 2",
              },
              {
                id: "01G74KPXVQYX0RZG5S9E9TTCMX",
                logo: "https://storage-passport-gg.s3.eu-central-1.amazonaws.com/gameLogo/01G74KPXVQYX0RZG5S9E9TTCMX/01GBGF6V320J1NYW2YS6NADH8C/original?v=1661631425",
                status: "active",
                groupName: "OTHER GAMES",
                title: "Clash of Clans",
              },
              {
                id: "01G2CHY9GXAQN51MP3BXW8HE66",
                logo: "https://storage-passport-gg.s3.eu-central-1.amazonaws.com/gameLogo/01G2CHY9GXAQN51MP3BXW8HE66/01GBGEW74M0CHGG9M2RJN4YPN8/original?v=1661631077",
                status: "active",
                groupName: "SPORTS SIMULATORS",
                title: "eFootball 2023",
              },
            ],
          };
        }
        setGames(res.games);
        setIsLoad(true);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setGamesFPS(games?.filter((value) => value?.groupName === "FPS"));
    setGamesMoba(games?.filter((value) => value?.groupName === "MOBA"));
    setGamesOther(games?.filter((value) => value?.groupName === "OTHER GAMES"));
    setGamesSport(
      games?.filter((value) => value?.groupName === "SPORTS SIMULATORS")
    );
    setGamesFight(
      games?.filter((value) => value?.groupName === "FIGHTING GAMES")
    );
    setGamesBattle(
      games?.filter((value) => value?.groupName === "BATTLE ROYALE")
    );
  }, [games]);

  function showToast() {
    toast.success(
      "Add the game to your profile so you can be added to the roster"
    );
  }

  const render = (games) => {
    return (
      <div className="games__list__container">
        <div className="games__list__group">{games[0]?.groupName}</div>
        <ul className="games__list">{renderGames(games)}</ul>
      </div>
    );
  };

  const renderGames = (gamesList) => {
    return gamesList.map((el) => (
      <li
        className={
          el?.id === selectedGame.id
            ? "games__item-list  games__item-list--active"
            : "games__item-list"
        }
        onClick={(e) => changeGameDiscipline(el)}
        key={el?.id}
      >
        <img
          className="games__item-list-image"
          src={el?.logo}
          alt={el?.title}
          width="100"
          height="100"
        />
        {el?.title.length > 16 ? (
          <p className="games__item-list-text">
            {el?.title.slice(0, 16) + "..."}
          </p>
        ) : (
          <p className="games__item-list-text">{el?.title}</p>
        )}
        {el?.id === selectedGame.id ? (
          <input
            type="text"
            value={nickInput}
            onChange={(e) => setNickInput(e.target.value)}
            className="games__input-name"
            placeholder={intl.formatMessage({
              id: "global.forms.labels.nickname",
              defaultMessage: "nickname",
            })}
          />
        ) : (
          ""
        )}
      </li>
    ));
  };

  return (
    <>
      {!isLoad && <LinearProgress />}
      {!!isLoad && (
        <div>
          <div className="games">
            <div className="games__title-icon">
              <h1 className="games__title">
                {" "}
                <FormattedMessage id="id.games" />
              </h1>
              <i
                className="icon icon-exclamation-mark"
                onClick={() => showToast()}
                style={{ background: "rgba(255, 0, 0, 0.626)" }}
              ></i>
            </div>
            {!!gamesFPS.length && render(gamesFPS)}
            {!!gamesMoba.length && render(gamesMoba)}
            {!!gamesBattle.length && render(gamesBattle)}
            {!!gamesSport.length && render(gamesSport)}
            {!!gamesFight.length && render(gamesFight)}
            {!!gamesOther.length && render(gamesOther)}

            <div className="submit-button-wrapper">
              <button
                className={`submit-button ${
                  !selectedGame ? "submit-button--disabled" : ""
                }`}
                onClick={() => addGame()}
              >
                <FormattedMessage id="global.forms.placeholders.select" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default injectIntl(Games);
