import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import classNames from "classnames";

import { API, API_ROUTER } from "../../api";

import { Preloader } from "../../components/UI";
import {
  MatchInformation,
  MatchMembers,
  RegisterMatch,
  MatchMembersFFA,
} from "./components";
import ResultMatchForm from "./components/ResultMatchForm";

import {
  MATCH_STATUS_ENDED,
  MATCH_STATUS_LIVE,
  MATCH_STATUS_SOON,
  TIME_UPDATE_MATCH,
  MATCH_STATUS_PENDING,
} from "./constants";
import styles from "./styles.module.scss";
import { APP_HOSTNAMES, DISCORD_LINKS } from "../../constants";

const MatchDiscussion = () => {
  const { matchId } = useParams();

  const [isLoad, setIsLoad] = useState(false);
  const [match, setMatch] = useState();
  const [isUpdateUserData, setIsUpdateUserData] = useState(false);
  const [isShowResultMatchForm, setIsShowResultMatchForm] = useState(true);
  const [membersList, setMembersList] = useState(null);
  const [isShowDiscordLink, setIsShowDiscordLink] = useState(true);
  const [isShowStaticDiscordLink, setIsShowStaticDiscordLink] = useState(true);

  const user = useSelector((state) => state.userData);
  const getIsIncludeUserInMatch = () => {
    const members = [
      ...membersList?.firstMembers?.map((el) => el?.id),
      ...membersList?.secondMembers?.map((el) => el?.id),
    ];
    setIsShowDiscordLink(
      !!members.find((el) => el === user?.id) ||
        match?.executorIsCapitan ||
        match?.executorIsJudge
    );
  };

  useEffect(() => {
    if (membersList && user) {
      getIsIncludeUserInMatch();
    }
  }, [user, membersList]);

  const getInfo = () => {
    const token = localStorage.getItem("token");

    if (token) {
      return getMatchInfo();
    }

    return getPublicMatchInfo();
  };

  const getMatchInfo = async () => {
    setIsLoad(true);
    await API.request({
      ...API_ROUTER.tournaments.getMatchInfoWithUrl,
      pathKeys: {
        number: matchId,
      },
    })
      .then((res) => {
        setMatch(res);
        getCountGames();
        if (res?.registrationIsOpen) {
          setTimeout(() => {
            getMatchInfo();
          }, TIME_UPDATE_MATCH);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoad(false));
  };

  const getPublicMatchInfo = async () => {
    setIsLoad(true);
    await API.request({
      ...API_ROUTER.tournaments.getPublicMatchInfoWithUrl,
      pathKeys: {
        number: matchId,
      },
    })
      .then((res) => {
        setMatch(res);
        getCountGames();
        if (res?.registrationIsOpen) {
          setTimeout(() => {
            getPublicMatchInfo();
          }, TIME_UPDATE_MATCH);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoad(false));
  };

  const getCountGames = () => {
    if (match) {
      const countGames = (match?.mode || []).split("");
      return countGames && Number(countGames[countGames.length - 1]);
    }

    return 1;
  };

  const isShowResultForm = () => {
    return match?.canChangeResult;
  };
  const isFFATournament = () => {
    return match?.tournamentStageType === "FFA";
  };
  const setVisibleResultMatchForm = () => {
    setIsShowResultMatchForm(false);
    getInfo();
  };

  const getStaticDiscordUrl = () => {
    if (window.location.hostname === APP_HOSTNAMES.ASIA_PASSPORT) {
      return DISCORD_LINKS.ASIA_PASSPORT;
    }

    return DISCORD_LINKS.PASSPORT;
  };

  const hideStaticButton = () => {
    localStorage.setItem("isShowDiscordStaticButton", "0");
    setIsShowStaticDiscordLink(false);
  };

  useEffect(() => {
    getInfo();

    const isShowStaticDiscordLink = localStorage.getItem(
      "isShowDiscordStaticButton"
    );
    if (isShowStaticDiscordLink) {
      setIsShowStaticDiscordLink(false);
    }
  }, []);

  return (
    <>
      {!!isLoad && <Preloader />}
      {!isLoad && match && (
        <div className={styles.match}>
          <MatchInformation match={match} />

          {match?.status === MATCH_STATUS_PENDING && (
            <div
              className={classNames(
                "matchlabelBg",
                styles.labelBeforeRegistration
              )}
            >
              <FormattedMessage id="match.ticker.resultPending" />
            </div>
          )}
          {/* {isShowDiscordLink && isShowResultForm() && ( */}
          <div
            className={classNames(styles.discordContainer, "discordContainer")}
          >
            <div className={styles.buttons}>
              {/* {!!match?.discordMatchTreadUrl && ( */}
              <a
                className={classNames(styles.discordButton, "discordButton")}
                href={getStaticDiscordUrl()}
                target="_blank"
                style={{ justifyContent: "flex-start" }}
              >
                <svg viewBox="0 0 640 512">
                  <path
                    fill="#fff"
                    d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"
                  />
                </svg>
                <span style={{ marginLeft: "20px" }}>Join our Discord</span>
              </a>
              {/* )} */}
              <div className={styles.icon}>
                <svg viewBox="0 0 640 512" style={{ visibility: "hidden" }}>
                  <path
                    fill="#fff"
                    d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"
                  />
                </svg>
              </div>
              <a
                className={classNames(
                  styles.discordButton,
                  styles.animationButton,
                  "discordButton"
                )}
                href={match?.discordMatchTreadUrl}
                target="_blank"
                onClick={hideStaticButton}
              >
                <div className={styles.textContainer}>
                  <span>Enter the match lobby</span>
                  <span className={styles.caption}>
                    (join our Discord channel to access)
                  </span>
                </div>
              </a>
            </div>
          </div>
          {/* )} */}
          {/* проверка для ffa турнира не показывать форму для ввода результатов */}
          {!isFFATournament() && (
            <section className={styles.discussion}>
              {isShowResultForm() && isShowResultMatchForm && (
                <ResultMatchForm
                  matchWinner={match?.matchWinner}
                  countGames={getCountGames()}
                  matchId={match?.matchId}
                  onClose={setVisibleResultMatchForm}
                />
              )}
            </section>
          )}

          {!!match?.signedUserMatch && match?.status === MATCH_STATUS_SOON && (
            <div className={styles.labelBeforeRegistration}>
              <FormattedMessage id="match.ticker.labelBeforeRegistration" />
            </div>
          )}
          {!!match?.signedUserMatch && match?.registrationIsOpen && (
            <RegisterMatch
              match={match}
              updateMemberData={() => setIsUpdateUserData(isUpdateUserData)}
            />
          )}
          {/* проверка на ffa турнир и вывод соответствующего компонента и ввода результатов */}
          {match?.tournamentStageType === "FFA" ? (
            <MatchMembersFFA
              match={match}
              isUpdateUserData={isUpdateUserData}
              setMembersList={setMembersList}
            />
          ) : (
            <MatchMembers
              match={match}
              isUpdateUserData={isUpdateUserData}
              setMembersList={setMembersList}
            />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};
export default injectIntl(connect(mapStateToProps)(MatchDiscussion));
