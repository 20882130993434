import React from 'react';

export default function IconNotifications() {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <g clipPath="url(#clip0)">
                    <path d="M23.7563 21.93L21.8951 18.8295C21.0371 17.3984 20.5831 15.7604 20.5831 14.091V11.3751C20.5831 7.1945 17.1804 3.79175 12.9998 3.79175C8.81926 3.79175 5.41651 7.1945 5.41651 11.3751V14.091C5.41651 15.7604 4.96257 17.3984 4.10457 18.8295L2.24339 21.93C2.14264 22.0968 2.14045 22.3059 2.23582 22.4749C2.33226 22.645 2.51314 22.7501 2.70814 22.7501H23.2915C23.4865 22.7501 23.6674 22.6451 23.7638 22.4749C23.8591 22.3059 23.857 22.0968 23.7563 21.93ZM3.66476 21.6667L5.033 19.3863C5.99282 17.7873 6.49982 15.9565 6.49982 14.091V11.3751C6.49982 7.79031 9.41507 4.87506 12.9998 4.87506C16.5846 4.87506 19.4998 7.79031 19.4998 11.3751V14.091C19.4998 15.9565 20.0068 17.7873 20.9656 19.3863L22.3349 21.6667H3.66476Z" fill="white"/>
                    <path d="M13.0002 0C11.8052 0 10.8335 0.97175 10.8335 2.16668V4.33337C10.8335 4.63232 11.0762 4.875 11.3752 4.875C11.6742 4.875 11.9169 4.63232 11.9169 4.33332V2.16668C11.9169 1.56868 12.4022 1.08337 13.0002 1.08337C13.5982 1.08337 14.0835 1.56868 14.0835 2.16668V4.33337C14.0835 4.63232 14.3262 4.875 14.6252 4.875C14.9242 4.875 15.1669 4.63232 15.1669 4.33332V2.16668C15.1669 0.97175 14.1951 0 13.0002 0Z" fill="white"/>
                    <path d="M15.3442 21.9343C15.1914 21.6765 14.861 21.592 14.6021 21.7404C14.3432 21.892 14.2565 22.2246 14.4082 22.4824C14.549 22.7219 14.6259 23.0089 14.6259 23.2917C14.6259 24.1876 13.8969 24.9167 13.0009 24.9167C12.105 24.9167 11.3759 24.1876 11.3759 23.2917C11.3759 23.0089 11.4529 22.7219 11.5937 22.4824C11.7443 22.2235 11.6576 21.892 11.3998 21.7404C11.1387 21.5919 10.8093 21.6764 10.6577 21.9343C10.4183 22.3438 10.2915 22.8129 10.2915 23.2917C10.2916 24.7856 11.5059 26 12.9999 26C14.4938 26 15.7082 24.7856 15.7104 23.2917C15.7104 22.8129 15.5836 22.3438 15.3442 21.9343Z" fill="white"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="26" height="26" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}