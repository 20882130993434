import placeHolder from "../../../../assets/fake-team.png";

import styles from "./styles.module.scss";

const imageWithPlaceHolder = (logo) => {
  return (
    <img
      className={styles.styledCard__image}
      src={logo ? logo : placeHolder}
      alt="img"
    />
  );
};
const dumb = () => {
  console.log("there is no action on this card");
};
export default function Card({
  add = false,
  title,
  isComing = false,
  icon = null,
  noTitle = "Title",
  role,
  logo,
  action = dumb,
  name,
}) {
  const NameOfCard = (value) => {
    return (
      <p>
        {value?.length > 15 ? value?.slice(0, 15) + "..." : value || noTitle}
        {isComing ? (
          <i
            className="icon icon-password"
            style={{ marginLeft: 5, width: 15, height: 15 }}
          />
        ) : (
          ""
        )}
      </p>
    );
  };
  return (
    <div className={styles.styledCard}>
      <section
        className={styles.styledCard__header}
        onClick={() => {
          !isComing ? action() : dumb();
        }}
      >
        {/* if add place PLUS else place image */}

        {add ? (
          <i className="icon icon-plus" style={{ background: "var(--icon)" }} />
        ) : icon ? (
          <i
            className={"icon icon-" + icon}
            style={{ width: "45px", height: "45px" }}
          />
        ) : (
          imageWithPlaceHolder(logo)
        )}
      </section>
      <section className={styles.styledCard__footer}>
        {/* Main user */}
        {!!role && NameOfCard(role ? role : "")}
        {!!name && NameOfCard(name ? name : "")}

        {!!title && NameOfCard(title)}
      </section>
    </div>
  );
}
