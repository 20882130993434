import React, { useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { ROUTER } from "../../config";
import { Container } from "../UI";
import HeaderNav from "./header-nav";
import HeaderSearch from "./search";
import HeaderNavButton from "./HeaderNavButton";
import HeaderUserPanel from "./HeaderUserPanel";

import logoLG from "../../assets/images/passport-logo.png";
import logoSM from "../../assets/logo-sm.png";

const StyledHeader = styled.header`
    margin: 0px 0px 10px;
  `,
  StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;

    @media (min-width: 768px) {
      height: 100px;
    }
  `,
  StyledLogo = styled.a`
    display: ${(props) => (props.navStatus ? "none" : "block")};

    .logo {
      img {
        display: block;
      }

      &-sm {
        display: none;

        @media (max-width: 767px) {
          display: block;
        }
      }

      &-lg {
        display: block;

        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  `,
  StyledControls = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: ${(props) => (props.navStatus ? "flex-start" : "center")};
    width: 100%;

    @media (min-width: 768px) {
      align-items: center;
    }
  `;

const Header = ({ history, style }) => {
  const [navState, setNavState] = useState(false);
  const isUserOnline = useSelector((state) => state.userOnline);
  const toHomepage = (e) => {
    e.preventDefault();
    history.push(
      isUserOnline ? "/calendar/tournaments" : "/calendar/tournaments"
    );
  };

  if (window.location.pathname === ROUTER.homepage) return null;
  if (window.location.pathname === ROUTER.login) return null;
  if (window.location.pathname === ROUTER.signup) return null;

  return (
    <StyledHeader className={classNames("headerBg")}>
      <StyledContainer>
        <StyledLogo
          href="/calendar/tournaments"
          navStatus={navState}
          onClick={toHomepage}
        >
          <div className="logo logo-sm">
            <img
              className={classNames("headerLogoImageSm")}
              alt="passport.gg"
            />
          </div>
          <div className="logo logo-lg">
            <img
              className={classNames("headerLogoImageLg")}
              alt="passport.gg"
            />
          </div>
        </StyledLogo>
        <StyledControls navStatus={navState}>
          {/* <HeaderSearch /> */}
          {/* TODO BIGBANG add props domen style  */}
          <HeaderNav navStatus={navState} style={style} />
          <HeaderUserPanel navStatus={navState} />
          <HeaderNavButton navStatus={navState} clickHandler={setNavState} />
        </StyledControls>
      </StyledContainer>
    </StyledHeader>
  );
};

export default withRouter(Header);
