import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { API, API_ROUTER } from "../../../../api";

import { isAuthenticated } from "../../../../helpers";

import { fulldate } from "./constant/date";

import banerAsia from "../../../../assets/images/event-asia-baner.png";
import gameLogo from "../../../../assets/games/apex-legends.png";
import calendar from "../../../../assets/events/calendar.svg";
import point from "../../../../assets/events/point.svg";

import styles from "./styles.module.scss";

function EventHeader({ event }, isFollow) {
  const [star, tongleStar] = useState(isFollow);

  const history = useHistory();

  function goToUrl(url) {
    history.push(url);
    if (!isAuthenticated()) {
      localStorage.removeItem("tournamentId");
      localStorage.removeItem("communityId");
      localStorage.removeItem("profileUrl");
      return localStorage.setItem("eventId", id);
    }
  }
  const tongStar = () => {
    API.request({
      ...API_ROUTER.follow.IsUserFollowEvent,
      pathKeys: {
        eventId: id,
      },
    })
      .then((res) =>
        res.follow === true
          ? API.request({
              ...API_ROUTER.follow.stopFollowEvent,
              pathKeys: {
                eventId: id,
              },
            })
              .then((res) => {
                toast.success(`You unfollowed this event`);
              })
              .catch((err) => console.log(err))
          : API.request({
              ...API_ROUTER.follow.followEvent,
              data: {
                eventId: id,
              },
            })
              .then((res) => {
                toast.success(`You followed this event`);
              })
              .catch((err) => console.log(err))
      )
      .catch((err) => console.log(err));
    if (star) {
      tongleStar(false);
    } else tongleStar(true);
  };

  return (
    <>
      <section
        className={styles.events__banner}
        style={{
          background: `url(${
            event?.profilePageHeaderBanner
              ? event?.profilePageHeaderBanner
              : gameLogo
          }) no-repeat`,
        }}
      >
        <img
          className={styles.events__banner__image}
          src={event?.logo ? event?.logo : gameLogo}
          alt="logo"
          width="90"
          height="90"
        />
      </section>

      <section className={styles.events__title}>
        {event?.status
          ? event?.status && (
              <p className={styles.events__title__life}>{event?.status}</p>
            )
          : false}

        <h3 className={styles.events__title__name}>
          {event?.name ? event?.name : "Game"}
        </h3>
        <article className={styles.events__heart__abs}>
          <div
            className={styles.events__button__followed}
            onClick={() => tongStar()}
          >
            <h1 className={styles.events__button__text}>Add game in profile</h1>
          </div>
        </article>
      </section>
    </>
  );
}
export default EventHeader;
