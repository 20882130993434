import React, { useState } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

//
import Card from "../Card";

import GameSwitcher from "../../../../components/game-switcher";

import styles from "./styles.module.scss";

export default function Row({
  title = "Title",
  cards = [],
  history,
  setTeamFilter,
  requestSuccess,
}) {
  const [selected, setSelected] = useState("owner");

  const filterTeams = (selected) => {
    if (selected === "owner") {
      setTeamFilter("member");
      setSelected("member");
    }
    if (selected === "member") {
      setTeamFilter("owner");
      setSelected("owner");
    }
  };
  return (
    <div className={styles.styledRow}>
      <section className={styles.styledRow__header}>
        <h1>{title}</h1>
        <GameSwitcher
          selectedGame={selected}
          gamesAvailable={["owner", "member"]}
          setSelectedGame={() => {
            filterTeams(selected);
          }}
        />
      </section>
      <section className={styles.styledRow__footer}>
        {!requestSuccess && <CircularProgress />}
        {!!requestSuccess && cards.length ? (
          cards.map((card) => (
            <Card {...card} key={card.id} history={history} />
          ))
        ) : (
          <div className="empty-data">There are no teams yet</div>
        )}
      </section>
    </div>
  );
}
