import { FormattedMessage } from "react-intl";
import React from "react";
import { v4 as uuidv4 } from "uuid";

export default function getGameCards(games = [], history, isCreator, teamId) {
  let gameCard;
  if (isCreator) {
    return (gameCard = [
      ...games,
      {
        id: uuidv4(),
        add: true,
        title: <FormattedMessage id="id.games.add" />,
        action: () => {
          history.push(`/teams/team/${teamId}/roster/discipline`);
        },
        img: "",
      },
    ]);
  }
  if (!isCreator) {
    return (gameCard = [...games]);
  }
  return gameCard;
}
