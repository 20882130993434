import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { gapi } from "gapi-script";
import classNames from "classnames";

import LangSwitcher from "../../components/lang-switcher";

import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import { GOOGLE_AUTH_CLIENT_ID, FACEBOOK_AUTH_CLIENT_ID } from "../../config";
//
import { getUserData, userOnline } from "../../redux/actions";
import { API, API_ROUTER } from "../../api";
import store from "../../redux/store";

//
import {
  getUrlParams,
  isPasswordValid,
  isCheckboxChecked,
  isEmailValid,
  isFieldEmpty,
  isSelectEmpty,
} from "../../helpers";
import { CheckBox, Select } from "../../components/UI";
//
import Logo from "../../components/logo";
import InputLogin from "../../components/UI/forms/input-logo_register";
import SelectSignup from "../../components/UI/forms/select-signup";

import ButtonLogin from "../../components/UI/buttons/buttons-login_register";
import authBg from "../../assets/images/fon.png";
// TODO img hogasia
import logo from "../../assets/images/passport-logo.png";
////////////////////////////////////////////////////////////////
import "../../assets/styles/index.css";
import "../../assets/styles/select.css";

import styles from "./styles.module.scss";

const SignUpForm = (props) => {
  const { countriesList } = props;

  const history = useHistory();
  const routeChange = () => {
    let path = `/login`;
    history.push(path);
  };

  const getDefaultErrors = () => {
    return {
      username: "",
      password: "",
      country: "",
      gdpr: "",
    };
  };
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [gdprChecked, setGdprChecked] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);
  const [errors, setErrors] = useState(getDefaultErrors);
  const [selectCountry, setSelectCountry] = useState([]);
  const [ip, setIP] = useState("");
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setIP(res.data.IPv4);
  };
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: GOOGLE_AUTH_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });
  console.log(countriesList);
  const country = selectCountry[0]?.value;
  console.log(country);

  useEffect(() => {
    const { userOnline, history } = props;

    if (userOnline) {
      props.runGetUserData();

      if (props?.userData) {
        console.log("props?.userData", props?.userData);

        history.push(`/id/${props?.userData?.url}`);
      }
    }
  }, [props.userOnline, props.userData]);

  const onInputChange = (type) => {
    return (e) => {
      const setter = type === "username" ? setUserName : setPassword;

      setter(e.target.value);
    };
  };
  const onCheckboxChange = (e) => {
    if (e.target.name === "gdpr") setGdprChecked(!gdprChecked);

    if (e.target.name === "marketing") setMarketingChecked(!marketingChecked);
  };

  // SIGNUP
  const actionSignUp = (e) => {
    e.preventDefault();
    const { history } = props;
    const isInvite = localStorage.getItem("team-invite");
    const URLParams = getUrlParams();

    // validate
    errors.username = isEmailValid(username);
    errors.password = isPasswordValid(password);
    errors.country = isSelectEmpty(selectCountry);
    errors.gdpr = isCheckboxChecked(gdprChecked);

    setErrors({ ...errors });
    console.log(errors);
    // send request if valid
    if (!Object.values(errors).some((value) => value.length)) {
      const registerMethod = API_ROUTER.auth.register;

      let params = {
        ...registerMethod,
        pathKeys: {
          eventName: "registration",
        },
        data: {
          username,
          password,
          country,
          gdpr: gdprChecked,
          marketing: marketingChecked,
          sourceUrl: "https://app.passport.gg/",
        },
      };

      // get referral
      if (URLParams.ref) {
        params.data.referral = URLParams.ref;
      }

      API.request(params, true)
        .then(({ token }) => {
          localStorage.setItem("token", token);

          props.runGetUserData();
          props.runUserOnline();

          const userData = store.getState().userData;

          toast.success("Successfully logged in");
          // TODO change on tournaments
          // history.push(`/calendar/tournaments`);
          history.push(`/id/${userData.url}`);
        })
        .catch((err) => {
          let errorMessage = null;

          if (err?.data?.errors) {
            if (err?.data?.errors?.username) {
              errorMessage = `The username ${username} already exists. Please sign in!`;
              errors.username = "User already exists";
            }
          }

          setErrors({ ...errors });

          if (errorMessage) {
            toast.error(errorMessage);
          }
        });
    }
  };
  const responseThirdAuth = (api, response) => {
    const { history } = props;

    const params = {
      ...api,
      data: {
        token: response.accessToken,
        ip: ip,
        sourceUrl: "https://app.passport.gg/",
      },
    };

    API.request(params, true)
      .then(({ token }) => {
        if (token) {
          localStorage.setItem("token", token);

          props.runGetUserData();
          props.runUserOnline();

          const userData = store.getState().userData;

          toast.success("Successfully logged in");
          history.push(`/id/${userData.url}`);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err?.data && err?.data?.message) {
          toast.error(err?.data && err?.data?.message);
        }
      });
  };
  const passwordPlaceholder = useIntl().formatMessage({
    id: "login.input.password",
  });
  const emailPlaceholder = useIntl().formatMessage({
    id: "resetPassword.enterEmail",
  });
  const countryPlaceholder = useIntl().formatMessage({
    id: "signup.countryPlaceholder",
  });
  return (
    <div className={classNames("bgGradient", styles.auth)}>
      <div className={styles.auth__container}>
        <Logo />
        <div className={styles.language__switch}>
          <LangSwitcher />
        </div>
        <p className={styles.auth__text}>
          <FormattedMessage id="publicHeader.h1" />
        </p>
        <form className={styles.auth__form} noValidate autoComplete="off">
          <div className={styles.input__group}>
            <InputLogin
              type="text"
              placeholder={emailPlaceholder}
              icon={"email"}
              name="username"
              className={styles.login__input}
              onChange={onInputChange("username")}
              value={username}
              error={errors.username}
            />
            <InputLogin
              type="password"
              placeholder={passwordPlaceholder}
              icon="password"
              name="password"
              className={styles.password__input}
              onChange={onInputChange("password")}
              value={password}
              error={errors.password}
            />

            <SelectSignup
              required
              icon="globus"
              placeholder={countryPlaceholder}
              error={errors.country}
              clearable
              options={countriesList}
              values={selectCountry}
              onChange={(e) => setSelectCountry(e, "country")}
            />
          </div>

          <div className={styles.access__wrapper}>
            <CheckBox
              required
              name="gdpr"
              onChange={onCheckboxChange}
              checked={gdprChecked}
              error={errors.gdpr}
            >
              <p className={styles.access__wrapper__text}>
                <FormattedMessage id="authModal.signUp.check1.full" />
                <br />{" "}
                <Link to={"/terms-of-use"}>
                  {" "}
                  <FormattedMessage id="authModal.signUp.check1.linkTerms" />
                </Link>{" "}
                <Link to={"/privacy-policy"}>
                  {" "}
                  <FormattedMessage id="authModal.signUp.check1.linkPrivacy" />
                </Link>{" "}
                <FormattedMessage id="signup.check.and" />{" "}
                <Link to={"/cookie-policy"}>
                  {" "}
                  <FormattedMessage id="authModal.signUp.check1.linkCookie" />
                </Link>
              </p>
            </CheckBox>
            <CheckBox
              name="marketing"
              onChange={onCheckboxChange}
              checked={marketingChecked}
            >
              <p className={styles.access__wrapper__text}>
                <FormattedMessage id="authModal.signUp.check2" />
              </p>
            </CheckBox>
          </div>

          <div className={styles.btn__group}>
            <ButtonLogin
              type="submit"
              name="signup"
              active={true}
              onClick={actionSignUp}
            >
              <FormattedMessage id="signup.signup" />
            </ButtonLogin>
          </div>
          <p className={styles.sign__up}>
            Already have account?
            <span onClick={routeChange}>
              <FormattedMessage id="login.loginUppercase" />
            </span>
          </p>
        </form>
        <div className={styles.footer__powerBy}>
          <a
            href="http://app.passport.gg/"
            target={"_blank"}
            style={{ textDecoration: "none" }}
          >
            Powered by &nbsp;&nbsp; &nbsp;&nbsp;
            <img src={logo} width="150px" />
          </a>
        </div>
        {/* <div className="social__auth">
        <p className="social__label">
          {" "}
          <FormattedMessage id="signup.createWith" />
        </p>
        <div className="social__wrapper">
          <div className="google">
            <GoogleLogin
              className="login-with__google"
              clientId={GOOGLE_AUTH_CLIENT_ID}
              buttonText={<i className="icon icon-google" />}
              onSuccess={(resp) =>
                responseThirdAuth(API_ROUTER.auth.googleAuthCheck, resp)
              }
              onFailure={(e) => {
                console.error(e);
              }}
              cookiePolicy={"single_host_origin"}
              icon={false}
            />
          </div>
          <div className="facebook">
            <FacebookLogin
              appId={FACEBOOK_AUTH_CLIENT_ID}
              cssClass="login-with__facebook"
              fields="name,email"
              scope="public_profile"
              callback={(resp) =>
                responseThirdAuth(API_ROUTER.auth.facebookAuthCheck, resp)
              }
              textButton={<i className="icon icon-facebook" />}
            />
          </div>
        </div>
      </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    countriesList: state.countriesList,
    userOnline: state.userOnline,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    runGetUserData: () => dispatch(getUserData()),
    runUserOnline: () => dispatch(userOnline()),
  };
};

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpForm))
);
