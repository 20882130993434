import React from "react";
import noImage from "../../assets/no-image.png";
export function userFlag(flag) {
  let cards = {
    defaultCard: (
      <div
        className="default-card"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div className="default-card__img" style={{ paddingRight: "15px" }}>
          <img
            src={flag ? flag : noImage}
            width="56"
            style={{ borderRadius: "5px" }}
          />
        </div>
      </div>
    ),
  };
  return cards;
}
