// auth
export const USER_ONLINE = "USER_ONLINE";
export const USER_OFFLINE = "USER_OFFLINE";
export const SHOW_AUTH_MODAL = "SHOW_AUTH_MODAL";
export const SHOW_QR_MODAL = "SHOW_QR_MODAL";
export const SHOW_MENU = "SHOW_MENU";
export const HIDE_AUTH_MODAL = "HIDE_AUTH_MODAL";
export const HIDE_QR_MODAL = "HIDE_QR_MODAL";
export const HIDE_MENU = "HIDE_MENU";
export const SHOW_REGISTER_MODAL = "SHOW_REGISTER_MODAL";
export const HIDE_REGISTER_MODAL = "HIDE_REGISTER_MODAL";
export const SHOW_AUTH_BLOCKED = "SHOW_AUTH_BLOCKED";
export const HIDE_AUTH_BLOCKED = "HIDE_AUTH_BLOCKED";

// notification modal
export const SHOW_NOTIFICATION_MODAL = "SHOW_NOTIFICATION_MODAL";
export const HIDE_NOTIFICATION_MODAL = "HIDE_NOTIFICATION_MODAL";

// steam data
export const SET_STEAM_DATA = "SET_STEAM_DATA";

// interface language
export const SET_INTERFACE_LANGUAGE = "SET_INTERFACE_LANGUAGE";

// messenger
export const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT";
export const PRESET_CHAT_MESSAGE = "PRESET_CHAT_MESSAGE";

// spinner
export const ADD_SPINNER = "ADD_SPINNER";
export const REMOVE_SPINNER = "REMOVE_SPINNER";

// user
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_NOTIFICATIONS = "SET_USER_NOTIFICATIONS";

// labels
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_PLATFORMS = "SET_PLATFORMS";
export const SET_DEVICES = "SET_DEVICES";
export const SET_LANGUAGES = "SET_LANGUAGES";

// scroll
export const SCROLL_TO = "SCROLL_TO";

// userGames
export const ADD_GAME = "ADD_GAME";
export const LOAD_GAMES = "LOAD_GAMES";
export const GET_USER_GAMES = "GET_USER_GAMES";

// roster
export const SET_CREATE_ROSTER_VALUES = "SET_CREATE_ROSTER_VALUES";
export const GET_CREATE_ROSTER_VALUES = "GET_CREATE_ROSTER_VALUES";

// userTournaments
export const ADD_TOURNAMENT = "ADD_TOURNAMENT";
export const LOAD_TOURNAMENTS = "LOAD_TOURNAMENTS";

// userCommunities
export const ADD_COMMUNITY = "ADD_COMMUNITY";
export const GET_USER_COMMUNITIES = "GET_USER_COMMUNITIES";

//communityDetails
export const ADD_COMMUNITY_DETAILS = "ADD_COMMUNITY_DETAILS";
export const GET_COMMUNITY_DETAILS = "GET_COMMUNITY_DETAILS";

//userAvatar
export const UPLOAD_AVATAR = "UPLOAD_AVATAR";

//modals
export const SHOW_PROFILE_INFO_MODAL = "SHOW_PROFILE_INFO_MODAL";
export const HIDE_PROFILE_INFO_MODAL = "HIDE_PROFILE_INFO_MODAL";
