import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import moment from "moment";
import classNames from "classnames";

import noImage from "../../../../assets/images/no-image.webp";
import {
  MatchInformationSectionDefault,
  MatchInformationSectionFFA,
  MatchInformationSectionDefaultMobile,
} from "./sections";
import styles from "./styles.module.scss";
import {
  MATCH_OFFLINE_TYPE,
  MATCH_STATUS_SOON,
  MATCH_STATUS_ENDED,
  MATCH_STATUS_TECHNICAL_DEFEAT,
} from "./constants";
import { FormattedMessage } from "react-intl";

export const MatchInformation = ({ match }) => {
  const history = useHistory();
  const [loser, setLoser] = useState();
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const getLogoTeam = (val) => {
    if (val?.team?.logo) {
      return val.team.logo;
    }

    if (val?.user?.avatar) {
      return val.user.avatar;
    }

    return noImage;
  };

  const getNameTeam = (val) => {
    if (val?.team?.name) {
      return val.team.name;
    }

    if (val?.user?.nickname) {
      return val.user.nickname;
    }

    return "-";
  };

  const getMemberUrl = (val) => {
    if (!val) {
      return;
    }

    if (val?.team) {
      return goToUrl(`/teams/team/${val.team?.id}`);
    }

    return goToUrl(`/id/${val?.user?.url}`);
  };

  const goToUrl = (url) => {
    history.push(url);
  };
  const getEndMatchDate = () => {
    const testDateUtc = moment.utc(
      match?.endedAt ? match?.endedAt : match?.startedAt
    );
    return moment(testDateUtc).local().format("D MMMM YYYY, h:mm a (UTC Z)");
  };

  const getStartMatchDate = () => {
    const testDateUtc = moment.utc(match?.startedAt);
    return moment(testDateUtc).local().format("D MMMM YYYY, h:mm a (UTC Z)");
  };

  const getTournearLogo = () => {
    if (match?.tournament?.logo) {
      return match.tournament.logo;
    }

    return noImage;
  };

  const getMatchLoser = () => {
    if (!match?.matchWinner) {
      return null;
    }

    const winner = match?.matchWinner;

    if (winner === match?.tournamentMember1?.id) {
      const name = getNameTeam(match?.tournamentMember2);
      return setLoser(name);
    }

    if (winner === match?.tournamentMember2?.id) {
      const name = getNameTeam(match?.tournamentMember1);
      return setLoser(name);
    }
  };

  const getBreadCrumbItemMatch = (value, link) => {
    return (
      !!value && (
        <div className={styles.item}>
          {link ? (
            <NavLink to={link} className={styles.link}>
              {value}
            </NavLink>
          ) : (
            <>{value}</>
          )}
        </div>
      )
    );
  };

  const getTypeMatch = () => {
    if (match.type === MATCH_OFFLINE_TYPE) {
      return `${match?.type} - ${match?.location}`;
    }

    return match?.type;
  };

  const getScoreCommand = (val) => {
    return match?.status === MATCH_STATUS_SOON ? "-" : val;
  };
  useEffect(() => {
    getMatchLoser();
  }, []);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  console.log(windowSize);
  return (
    <div className={styles.match}>
      <div className={styles.hierarchy}>
        {getBreadCrumbItemMatch(match.game?.title)}
        {getBreadCrumbItemMatch(
          match.community?.name,
          `/community/${match.community?.id}/events`
        )}
        {getBreadCrumbItemMatch(
          match.event?.name,
          `/eventPage/${match.event?.id}`
        )}
        {getBreadCrumbItemMatch(
          match.tournament?.name,
          `/battleCup/${match.tournament?.id}/information`
        )}
      </div>
      <div className={`${styles.info} ${styles.alignCenter}`}>
        <img className={styles.logo} src={getTournearLogo()} alt="match-logo" />
        <div>
          <div className={styles.description}>
            {!!match?.isPro && (
              <span className={classNames("matchInfoProBg", styles.pro)}>
                PRO
              </span>
            )}
            <NavLink to={`/battleCup/${match?.tournament?.id}/information`}>
              {match?.tournament?.name},
            </NavLink>
            <span className={styles.type}> {getTypeMatch()}</span>
          </div>
          <div className={styles.teamsName}>
            {/* не показывать команды в FFA */}
            {match?.tournamentStageType !== "FFA" && (
              <>
                <span onClick={() => getMemberUrl(match?.tournamentMember1)}>
                  {getNameTeam(match?.tournamentMember1)}
                </span>
                <span>vs </span>
                <span onClick={() => getMemberUrl(match?.tournamentMember2)}>
                  {getNameTeam(match?.tournamentMember2)}
                </span>
              </>
            )}
          </div>
          <div className={styles.description}>
            <strong>
              <FormattedMessage id="match.ticker.matchId" />:
            </strong>
            <span className={styles.span}>{match?.name}</span>
          </div>
        </div>
      </div>
      {/* разные секции для разных типов матчей */}
      {match?.tournamentStageType === "FFA" ? (
        <MatchInformationSectionFFA
          match={match}
          loser={loser}
          getMatchLoser={getMatchLoser}
          getScoreCommand={getScoreCommand}
          getStartMatchDate={getStartMatchDate}
          getEndMatchDate={getEndMatchDate}
          getMemberUrl={getMemberUrl}
          getNameTeam={getNameTeam}
          getLogoTeam={getLogoTeam}
        />
      ) : windowSize > 680 ? (
        <MatchInformationSectionDefault
          match={match}
          loser={loser}
          getMatchLoser={getMatchLoser}
          getScoreCommand={getScoreCommand}
          getStartMatchDate={getStartMatchDate}
          getEndMatchDate={getEndMatchDate}
          getMemberUrl={getMemberUrl}
          getNameTeam={getNameTeam}
          getLogoTeam={getLogoTeam}
        />
      ) : (
        <MatchInformationSectionDefaultMobile
          match={match}
          loser={loser}
          getMatchLoser={getMatchLoser}
          getScoreCommand={getScoreCommand}
          getStartMatchDate={getStartMatchDate}
          getEndMatchDate={getEndMatchDate}
          getMemberUrl={getMemberUrl}
          getNameTeam={getNameTeam}
          getLogoTeam={getLogoTeam}
        />
      )}
    </div>
  );
};

export default MatchInformation;
