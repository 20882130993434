import React from "react";
import RulebookHOC from "./rulebookHOC";

const termsOfUse = () => {
  return (
    <>
      <RulebookHOC />
    </>
  );
};

export default termsOfUse;
