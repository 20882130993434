import styled from "styled-components";
import { InnerBox } from "../../../components/UI";
import verefied from "../../../assets/icons/verification.svg";

const Styled = {
  Row: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  `,
  Col: styled.div`
    padding: 0 5px;
    width: 50%;

    @media (max-width: 767px) {
      width: 100%;

      & + & {
        margin-top: 10px;
      }
    }

    &.about {
      width: 100%;
      margin-top: 10px;
    }

    &.social {
      margin-top: 10px;
    }

    &.controls {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button + button {
        margin-left: 12px;
      }

      @media (max-width: 767px) {
        width: 100%;
        flex-direction: column;

        button + button {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  `,
  StyledInnerBox: styled(InnerBox)`
    height: 100%;
  `,
  Content: styled.div`
    .logos {
      display: flex;
      justify-content: center;
      margin: 0 -10px;

      .logo {
        padding: 0 10px;
        width: 33.3333%;
        display: flex;
        justify-content: center;

        img {
          display: block;
          max-width: 100%;
        }
      }
    }

    .qr {
      display: flex;
      justify-content: center;

      img {
        min-width: 100%;
      }
    }

    .nickname {
      text-align: center;
      font-size: 21px;
      font-weight: bold;
      margin: 0 0 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      svg {
        position: relative;
        top: -4px;
        margin-left: 6px;
      }
    }

    .username {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      margin: 0 0 4px;
    }

    .role {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
    }

    .sectionTitle {
      font-weight: 500;
      font-size: 21px;
      margin: 0 0 16px;

      &.next {
        margin: 16px 0;
      }

      &.withSwitcher {
        display: flex;
        align-items: center;
      }
      &.moreInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .moreInfo {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.03em;
        text-decoration-line: underline;
        text-transform: capitalize;
        text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        cursor: pointer;
      }

      strong {
        font-weight: bold;
      }
    }

    .dataRow {
      font-size: 14px;
      display: flex;
      align-items: flex-start;

      @media (min-width: 992px) and (max-width: 1199px) {
        flex-direction: column;
      }

      &.center {
        justify-content: center;
      }

      & + .dataRow {
        margin-top: 6px;
      }

      > div {
        &:first-child {
          min-width: 90px;
          padding-right: 16px;
          flex-shrink: 0;
        }
      }

      &.row-type {
        display: block;
      }
    }
  `,
  AvatarHolder: styled.div`
    width: 118px;
    height: 118px;
    margin: 0 auto 16px;
    position: relative;
  `,
  Avatar: styled.div`
    width: 118px;
    height: 118px;
    border-radius: 10px;
    overflow: hidden;
    background: ${(props) =>
      props.image
        ? `#eee url(${props.image}) no-repeat center center`
        : "#eee"};
    background-size: cover;
    border: ${(props) => !!props.pro && "2px solid gold"};
  `,
  VerifiedStatus: styled.div`
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: -7px;
    right: -7px;
    content: url(${verefied});
    ${
      "" /* &:hover,
    &:after {
      content: "verified by: community";
      position: absolute;
      left: 0;
      top: 24px;
      min-width: 200px;
      border-radius: 10px;
      background-color: rgb(32, 25, 65);
      padding: 12px;
      font-size: 14px;
      z-index: 1;
    } */
    }
  `,

  About: styled.div`
    font-size: 14px;
    padding-left: 30px;
    position: relative;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  `,
};

export default Styled;
