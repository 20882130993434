import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";

import { API, API_ROUTER } from "../../../../api";
import { isAuthenticated } from "../../../../helpers";
import { convertDate } from "./constants";

import styles from "./styles.module.scss";
import AsiaLogo from "../../../../assets/images/IESFBigBangAsia.png";
import starImage from "../../../../assets/svg/Star.svg";
import starGoldImage from "../../../../assets/svg/StarGold.svg";

const EventItem = ({
  id,
  name,
  logo,
  startDate,
  startedAt,
  endedAt,
  endDate,
  follow,
}) => {
  const history = useHistory();

  const [star, tongleStar] = useState(!follow);

  const followHandler = () => {
    API.request({
      ...API_ROUTER.follow.IsUserFollowEvent,
      pathKeys: {
        eventId: id,
      },
    })
      .then((res) =>
        res.follow === true
          ? API.request({
              ...API_ROUTER.follow.stopFollowEvent,
              pathKeys: {
                eventId: id,
              },
            })
              .then((res) => {
                toast.success(res.message);
              })
              .catch((err) => console.log(err))
          : API.request({
              ...API_ROUTER.follow.followEvent,
              data: {
                eventId: id,
              },
            })
              .then((res) => {
                toast.success(res);
              })
              .catch((err) => console.log(err))
      )
      .catch((err) => console.log(err));

    if (star) {
      tongleStar(false);
    } else tongleStar(true);
  };

  function goToUrl(url) {
    history.push(url);
  }
  return (
    <li
      className={classNames("calendarItemBorder", styles.event)}
      style={{}}
      key={id}
    >
      <img
        src={logo ? logo : AsiaLogo}
        className={styles.eventLogo}
        onClick={() => goToUrl(`/eventPage/${id}`)}
      />
      <div
        className={styles.eventDescription}
        onClick={() => goToUrl(`/eventPage/${id}`)}
      >
        <h4 className={styles.eventName}>{name ? name : "Event"}</h4>
        <div className={styles.eventDecoration} />
        <p className={styles.eventDate}>
          {convertDate(
            startedAt ? startedAt : startDate,
            endedAt ? endedAt : endDate
          )}
        </p>
      </div>
      {!!isAuthenticated() && (
        <img
          // If followed Golden else Default
          src={star ? starImage : starGoldImage}
          className={styles.event__star}
          onClick={() => followHandler()}
        />
      )}
    </li>
  );
};

export default EventItem;
