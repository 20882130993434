import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";

import { API, API_ROUTER } from "../../../../api";

import { Modal } from "../../../UI";
import placeHolder from "../../../../assets/fake-team.png";

import styles from "./styles.module.scss";

const imageWithPlaceHolder = (logo) => {
  return (
    <img
      className={styles.modal__info__logo}
      src={logo ? logo : placeHolder}
      alt="img"
    />
  );
};
export default function TournamentModalInformation({
  showQrModal,
  setOpenModal,
  team,
  teamRoster,
  user,
  name,
  tournamentId,
  tournamentMemberId,
}) {
  const history = useHistory();

  const cancelTournamentRegistration = () => {
    API.request({
      ...API_ROUTER.tournaments.deleteTournamentMember,
      pathKeys: { tournamentMemberId: tournamentMemberId },
    })
      .then((res) => {
        toast.success("You have successfully cancel registration");
      })
      .catch((err) =>
        toast.error(err.data.message ? err.data.message : "ERROR")
      );
  };

  const renderPlayers = (playersList) => {
    return playersList?.map((el) => {
      return (
        <>
          <div className={styles.modal__info__footer__players__container}>
            <div
              className={styles.footer__players__container__avatar}
              onClick={() => {
                window.location.href = `/id/${el?.url}`;
              }}
            >
              {imageWithPlaceHolder(el?.avatar)}

              {el?.gamerole === "capitanTeam" ? <p>Captain</p> : <p>Player</p>}
            </div>
            <div className={styles.footer__players__container__name}>
              {NameOfCard(el?.nickname ? el?.nickname : "")}
            </div>
          </div>
        </>
      );
    });
  };
  const NameOfCard = (value) => {
    return <p>{value.length > 15 ? value.slice(0, 15) + "..." : value}</p>;
  };
  return (
    <>
      {/* TEAM TYPE */}
      {!!team && (
        <Modal
          className={styles.modal__wrapper}
          isTransparent={true}
          open={showQrModal}
          onClose={() => setOpenModal(!true)}
        >
          <div className={classNames("modalBg", styles.modal)}>
            <div
              className={classNames("modalTopBg", styles.modal__header__about)}
            >
              <div className={styles.modal__header__about__title}>{name}</div>
              <i
                onClick={() => setOpenModal(!true)}
                className="icon icon-exit"
                style={{ width: "36px", height: "36px", marginRight: "13px" }}
              />
            </div>

            <div className={styles.modal__title}>
              <div className={styles.modal__info}>
                <div className={styles.modal__info__header__type}>Team</div>
                <div className={styles.modal__info__header__players__container}>
                  <div
                    className={styles.modal__info__header}
                    onClick={() => {
                      window.location.href = `/teams/team/${team?.teamId}`;
                    }}
                  >
                    {imageWithPlaceHolder(team?.logo)}
                  </div>
                  <div className={styles.modal__info__name}>
                    {/* Main user */}
                    <p>{team?.name ? team?.name : "Team"}</p>
                  </div>
                </div>

                <div className={styles.modal__info__footer__type}>
                  Team Members
                </div>
                <div className={styles.modal__info__footer__players}>
                  {renderPlayers(team?.teamPlayers)}
                </div>
              </div>
            </div>
            <div className={styles.modal__title__buttons}>
              <div
                className={styles.buttons__cancel}
                onClick={() => {
                  cancelTournamentRegistration();
                }}
              >
                <p>Cancel registration</p>
              </div>
              <div
                className={styles.buttons__goTo}
                onClick={() => {
                  history.push(`/battleCup/${tournamentId}/information`);
                }}
              >
                <p>Go To Tournament</p>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {/* USER TYPE */}
      {!!user && (
        <Modal
          className={styles.modal__wrapper}
          isTransparent={true}
          open={showQrModal}
          onClose={() => setOpenModal(!true)}
        >
          <div className={classNames("modalBg", styles.modal)}>
            <div
              className={classNames("modalTopBg", styles.modal__header__about)}
            >
              <div className={styles.modal__header__about__title}>{name}</div>
              <i
                onClick={() => setOpenModal(!true)}
                className="icon icon-exit"
                style={{ width: "36px", height: "36px", marginRight: "13px" }}
              />
            </div>

            <div className={styles.modal__title}>
              <div className={styles.modal__info}>
                <div className={styles.modal__info__header__type}>Player</div>
                <div className={styles.modal__info__header__players__container}>
                  <div
                    className={styles.modal__info__header}
                    onClick={() => {
                      window.location.href = `/id/${user?.userUrl}`;
                    }}
                  >
                    {imageWithPlaceHolder(user?.avatar)}
                  </div>
                  <div className={styles.modal__info__name}>
                    {/* Main user */}
                    <p>{user?.name ? user?.name : "Team"}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.modal__title__buttons}>
              <div
                className={styles.buttons__cancel}
                onClick={() => {
                  cancelTournamentRegistration();
                }}
              >
                <p>Cancel registration</p>
              </div>
              <div
                className={styles.buttons__goTo}
                onClick={() => {
                  history.push(`/battleCup/${tournamentId}/information`);
                }}
              >
                <p>Go To Tournament</p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
