import styled, { css } from "styled-components";

export const Styled = {
  Button: styled.button`
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    padding: 16px 45px;
    white-space: nowrap;

    &.primary {
      background-color: #ffa900;
      color: var(--dark);

      &:hover,
      &:focus {
        background: #bd810d;
      }

      &:active {
        background-color: #fff;
        color: #000;
      }
    }
    &.changePassword {
      background: #ffa900;
      width: 100%;
      border-radius: 5px;

      &:active {
        background: #8b6ffe;
      }
    }
    &.settingsCancel {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #fff;

      &:active {
        background: #8b6ffe;
      }
    }
    &.settingsSave {
      background: #ffa900;
      width: 100%;
      border-radius: 5px;

      &:active {
        background: #8b6ffe;
      }
    }
    &.secondary {
      color: #000;
      background: #f1f1f1;

      &:active {
        background: #8b6ffe;
      }
    }

    &.sm {
      height: 36px;
      padding: 0 12px;
      font-size: 14px;
    }

    &.full-width {
      width: 100%;
    }

    &[disabled] {
      background: #999;
      pointer-events: none;
    }
  `,
};
