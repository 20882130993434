import React, { useEffect, useState } from "react";
import { API, API_ROUTER } from "../../api";
import { FormattedMessage } from "react-intl";
import { LinearProgress } from "@material-ui/core";
import NotificationItem from "./notifi-item";
import { Pagination } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Container, ContentBox } from "../../components/UI";

import styles from "./styles.module.scss";
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#fff",
    },
  },
}));
const Notification = () => {
  const [notification, setNotification] = useState([]);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    API.request({
      ...API_ROUTER.notifications.getUserNotifications,
      pathKeys: {
        page: page ? page : 1,
        limit: 10,
      },
    })
      .then((res) => {
        setPagination(res.paginator);
        setNotification(res.notifications);
        setRequestSuccess(true);
      })
      .catch((err) => console.log(err));
  }, [page]);

  const renderNotification = (notifications) => {
    if (!notifications.length)
      return <div className={styles.noNotifications}>no notifications</div>;
    return (
      <div className="notification">
        <section className="notification__result">
          {notifications.map((items) => (
            <div key={items.id}>
              <article className="notification__result-events">
                {/* <h2 className="notification__result-title">{type}</h2> */}

                <ul className="notification__result-list">
                  <NotificationItem {...items} key={items.id} />
                </ul>
              </article>
            </div>
          ))}
        </section>
        {(notifications?.length > 9) | !pagination?.hasNextPage ? (
          <div className="notification__pagination">
            <Pagination
              classes={{ ul: classes.ul }}
              count={pagination?.lastPage}
              page={pagination?.currentPage}
              onChange={handleChange}
              variant="outlined"
            />
          </div>
        ) : (
          false
        )}
      </div>
    );
  };

  return (
    <>
      {requestSuccess ? renderNotification(notification) : <LinearProgress />}
    </>
  );
};

export default Notification;
