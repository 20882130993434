import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import Wrapper from "../../../components/wrapper";
import { Modal, Button } from "../../../components/UI";
import { isAuthenticated } from "../../../helpers";

import modalLogo from "../../../assets/images/passport-logo/passport-logo-clean.png";
import styles from "./styles.module.scss";

const TeamDoesnotExistModal = ({ teamDoesnotExist, userUrl }) => {
  let history = useHistory();

  return (
    <Wrapper>
      <Modal
        className={styles.modal__wrapper}
        isTransparent={true}
        open={teamDoesnotExist}
      >
        <div className={classNames("modalBg", styles.modal)}>
          <img
            className={classNames("modalLogo", styles.modal__image)}
            alt="modal__image"
          />

          <div className={styles.modal__title}>
            <div className={styles.modal__title__header}>oops :(</div>
            <div className={styles.modal__title__header}>
              it seems this team does not exist, you can contact{" "}
              <a
                className={styles.support__button}
                href="https://about.passport.gg/supportpage"
                target={"_blank"}
              >
                <FormattedMessage id="footer.support" />
              </a>
            </div>
            <div className={styles.modal__title__buttons}>
              {!!isAuthenticated() ? (
                <Button
                  label="GO TO YOUR PROFILE"
                  type="button"
                  color="white"
                  active
                  size="sm"
                  variant="changePassword"
                  action={() => {
                    window.location.href = `/id/${userUrl}`;
                  }}
                />
              ) : (
                <Button
                  label="GO TO TOURNAMENTS"
                  type="button"
                  color="white"
                  active
                  size="sm"
                  variant="changePassword"
                  action={() => {
                    window.location.href = `/calendar/tournaments`;
                  }}
                />
              )}

              <p onClick={history.goBack}>GO BACK</p>
            </div>
          </div>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default TeamDoesnotExistModal;
