import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Pagination } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import { API, API_ROUTER } from "../../api";

import { CustomSelect } from "../../components/form";
import { DateInput, Preloader } from "../../components/UI";
import { MatchItem } from "./components";

import "../../assets/styles/Notification.css";
import styles from "./styles.module.scss";

import close from "../../assets/close.svg";

import {
  MATCHES_STATE_FILTER,
  MATCHES_FILTER,
  MATCHES_STATUS_FILTER,
} from "./constants";
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#fff",
    },
  },
}));
const HoldingTournaments = () => {
  const classes = useStyles();

  const [matches, setMatches] = useState([]);
  const [games, setGames] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [route, setRoute] = useState();
  const [defaultGameValueFilter, setDefaultGameValueFilter] = useState(null);
  const [defaultStateValueFilter, setDefaultStateValueFilter] = useState(null);
  const [defaultValueFilter, setDefaultValueFilter] = useState(
    MATCHES_FILTER[0]
  );
  const [currentGame, setCurrentGame] = useState("");
  const [currentState, setCurrentState] = useState("");
  const [fromDateValue, setFromDateValue] = useState();
  const [toDateValue, setToDate] = useState();

  const [pageMatches, setPageMatches] = useState(1);
  const [pagination, setPagination] = useState(1);

  const getMatches = async () => {
    setIsLoad(true);

    setMatches([]);
    let urlParams = null;
    if (currentGame) {
      urlParams = {
        ...urlParams,
        game: currentGame,
      };
    }
    if (currentState) {
      urlParams = {
        ...urlParams,
        state: currentState,
      };
    }

    if (fromDateValue) {
      urlParams = {
        ...urlParams,
        fromDate: moment(fromDateValue).format("YYYY-MM-DD"),
      };
    }

    if (toDateValue) {
      urlParams = {
        ...urlParams,
        toDate: moment(toDateValue).format("YYYY-MM-DD"),
      };
    }

    await API.request({
      ...route,
      urlParams: {
        ...urlParams,
      },
      pathKeys: {
        page: pageMatches ? pageMatches : 1,
        limit: 10,
      },
    })
      .then((res) => {
        setMatches(res.matches);
        setPagination(res.paginator);
        setIsLoad(false);
      })
      .catch((err) => console.log(err));
  };

  const getAllGames = () => {
    API.request({
      ...API_ROUTER.games.getGames,
    })
      .then((res) => {
        const newVal = res?.games?.map((el) => {
          return {
            id: el.id,
            value: el.title,
          };
        });
        setGames(newVal);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (event, value) => {
    setPageMatches(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const changeMatchFilter = (val) => {
    const newValue = MATCHES_FILTER.find((el) => el.id == val);
    localStorage.setItem("matchFilter", val);
    const newRoute = newValue?.route;

    setDefaultValueFilter(newValue);
    if (newRoute) {
      setRoute(newRoute);
    } else {
      setRoute(MATCHES_FILTER[0]?.route);
    }
  };

  const changeMatchGameFilter = (val) => {
    setCurrentGame(val);
    const newValue = games?.find((el) => el.id === val);
    setDefaultGameValueFilter(newValue);
  };

  const changeMatchStateFilter = (val) => {
    setCurrentState(val);

    const newValue = MATCHES_STATE_FILTER?.find((el) => el.id === val);
    setDefaultStateValueFilter(newValue);
  };

  const setDefaultValueWithStorage = () => {
    const matchFilterValue = localStorage.getItem("matchFilter");
    const matchFilterGameValue = JSON.parse(
      localStorage.getItem("matchFilterGame")
    );

    if (matchFilterValue) {
      const newValue = MATCHES_FILTER.find((el) => el.id == matchFilterValue);

      const newRoute = newValue?.route;
      setDefaultValueFilter(newValue);
      if (newRoute) {
        setRoute(newRoute);
      }
    } else {
      setRoute(MATCHES_FILTER[0]?.route);
    }

    if (matchFilterGameValue && Object.keys(matchFilterGameValue).length) {
      setCurrentGame(matchFilterGameValue.id);
      setDefaultGameValueFilter(matchFilterGameValue);
    }
  };

  const clearField = () => {
    setCurrentGame("");
    setDefaultGameValueFilter("");
    setDefaultStateValueFilter("");
    setCurrentState("");
    setFromDateValue("");
    setToDate("");
    setRoute(MATCHES_FILTER[0]?.route);
    setDefaultValueFilter(MATCHES_FILTER[0]);
  };

  useEffect(() => {
    getMatches();
  }, [
    route,
    currentGame,
    currentState,
    fromDateValue,
    toDateValue,
    pageMatches,
  ]);

  useEffect(() => {
    getAllGames();
    setDefaultValueWithStorage();
  }, []);

  return (
    <div>
      <div className={styles.filterWrapper}>
        <CustomSelect
          defaultValue={defaultStateValueFilter}
          label="match.ticker.selectStatus"
          onChange={changeMatchStateFilter}
          options={MATCHES_STATE_FILTER}
          className={styles.select}
        />
        <CustomSelect
          defaultValue={defaultGameValueFilter}
          label="match.ticker.selectGame"
          onChange={changeMatchGameFilter}
          options={games}
          className={styles.select}
        />
        <CustomSelect
          defaultValue={defaultValueFilter}
          options={MATCHES_FILTER}
          onChange={changeMatchFilter}
          className={styles.select}
        />

        <div className={styles.datePickerWrapper}>
          <DateInput
            placeholderText="From date"
            selected={fromDateValue}
            onChange={(val) => {
              setFromDateValue(val);
            }}
          />
        </div>
        <div className={styles.datePickerWrapper}>
          <DateInput
            placeholderText="To date"
            selected={toDateValue}
            onChange={(val) => {
              setToDate(val);
            }}
          />
        </div>
        <button className={styles.transparentButton} onClick={clearField}>
          <img src={close} alt="close" />
        </button>
      </div>
      <div className={styles.matches}>
        {isLoad && <Preloader />}
        {!isLoad && (
          <>
            {matches?.map((el) => (
              <MatchItem
                key={el.matchId}
                name={el?.name}
                tournamentLogo={el.tournament.logo}
                tournamentName={el.tournament.name}
                nameGame={el.game.name}
                gameLogo={el.game.logo}
                tournamentMember1={el.tournamentMember1 || 0}
                tournamentMember2={el.tournamentMember2 || 0}
                tournamentMemberScore1={el.tournamentMemberScore1}
                tournamentMemberScore2={el.tournamentMemberScore2}
                mode={el?.mode}
                groupsName={el.round.name}
                startedAt={el.startedAt}
                matchId={el.matchId}
                status={el.status}
                tournamentStageType={el.tournamentStageType}
                game={el.game}
                round={el.round}
              />
            ))}
            {!matches?.length && (
              <p className={styles.text}>
                <FormattedMessage id="match.ticker.noMoreMatch" />
              </p>
            )}
          </>
        )}
        {(matches?.length > 9) | !pagination?.hasNextPage ? (
          <div className="notification__pagination">
            <Pagination
              classes={{ ul: classes.ul }}
              count={pagination?.lastPage ? pagination?.lastPage : 1}
              page={pagination?.currentPage ? pagination?.currentPage : 1}
              onChange={handleChange}
              variant="outlined"
            />
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  );
};

export default HoldingTournaments;
