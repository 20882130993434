import { useState, useEffect } from "react";
import { LinearProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import getCommunityDetailsHelper from "../../helpers/userCommunities/getCommunityDetailsHelper";

import CommunityHeader from "./sections/header";
import CommunityBody from "./sections/body";
import CommunityFooter from "./sections/footer";

import Wrapper from "../../components/wrapper";

import CommunityDoesnotExistModal from "./modal";

export default function EventPage() {
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [community, setCommunity] = useState();
  const [communityDoesnotExist, setCommunityDoesNotExist] = useState(false);
  const [follow, tongleFollow] = useState();
  const userData = useSelector((state) => state.userData);

  // get params community id
  let { communityId } = useParams();
  // get community info

  useEffect(() => {
    getCommunityDetailsHelper(communityId)
      .then((res) => {
        setCommunity(() => res);
        tongleFollow(res.follow);
        setRequestSuccess(true);
      })
      .catch((err) => {
        setCommunityDoesNotExist(!!err?.data?.errors?.communityId && true);
      });
  }, []);

  return (
    <>
      {!!requestSuccess && (
        <>
          <Wrapper>
            <CommunityHeader community={community} isFollow={follow} />
            <CommunityBody community={community} />
            <CommunityFooter community={community} />
          </Wrapper>
        </>
      )}
      {!requestSuccess && (
        <>
          {/* Show modal error */}
          {!!communityDoesnotExist && (
            <CommunityDoesnotExistModal
              communityDoesnotExist={communityDoesnotExist}
              userUrl={userData?.url}
            />
          )}
          <LinearProgress />
        </>
      )}
    </>
  );
}
