import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import TeamDoesnotExistModal from "./modal";
import { Modal, Container } from "../../components/UI";
import { Styled } from "../../components/auth-modal/style";
import { FormattedMessage } from "react-intl";
import { hideQrModal } from "../../redux/actions";
import { connect } from "react-redux";
import logo from "../../assets/images/passport-logo.png";
import Dashboard from "./Dashboard";
import { API, API_ROUTER } from "../../api";
import { toast } from "react-toastify";
import { isAuthenticated } from "../../helpers";

const Team = ({ showQrModal, dispatchHideQrModal, ...rest }) => {
  const userData = useSelector((state) => state.userData);

  const { match } = rest;
  const { teamId } = match.params;

  const [isCreator, setIsCreator] = useState(false);
  const [team, setTeam] = useState(null);
  const [qr, setQr] = useState("");

  const [teamDoesnotExist, setTeamDoesNotExist] = useState(false);
  useEffect(() => {
    getData();
    getQR();
  }, []);

  function getQR() {
    API.request({
      ...API_ROUTER.getTeamQR,
      pathKeys: {
        teamId: teamId,
      },
    })
      .then((res) => {
        setQr(res);
      })
      .catch((err) => console.log(err));
  }

  function getData() {
    {
      isAuthenticated()
        ? API.request(
            {
              ...API_ROUTER.teams.getTeamDetails,
              pathKeys: {
                teamId: teamId,
              },
            },
            true
          )
            .then((data) => {
              setTeam(data);
              setIsCreator(data.hasAccessEdit);
            })
            .catch((err) => {
              if (!teamId) {
                toast.error("Unable to load team data without team id");
              }
              toast.error(err.data && err.data.message);
              setTeamDoesNotExist(!!err?.data?.errors?.teamId && true);
            })
        : API.request(
            {
              ...API_ROUTER.teams.getTeamPublicDetails,
              pathKeys: {
                teamId: teamId,
              },
            },
            true
          )
            .then((data) => {
              setTeam(data);
            })
            .catch((err) => {
              if (!teamId) {
                toast.error("Unable to load team data without team id");
              }
              toast.error(err.data && err.data.message);
            });
    }
  }

  return (
    <>
      <Container>
        <Dashboard
          teamId={teamId}
          {...rest}
          team={team}
          isCreator={isCreator}
        />

        <Modal
          className="modal__wrapper"
          isTransparent={true}
          open={showQrModal}
          onClose={dispatchHideQrModal}
        >
          <div className="modal">
            <img className="qr__owl-eye" src={logo} alt="owl-eye" />
            <p className="qr__title">Team id Card</p>
            <div className="qr">
              <img className="qr__img" src={qr.url} alt="QR code" />
            </div>
            <Styled.ButtonHolder>
              <button className="qr__hide-qr" onClick={dispatchHideQrModal}>
                <FormattedMessage id="gamer.id.card.btn.close" />
              </button>
            </Styled.ButtonHolder>
          </div>
        </Modal>
        {!!teamDoesnotExist && (
          <TeamDoesnotExistModal
            teamDoesnotExist={teamDoesnotExist}
            userUrl={userData?.url}
          />
        )}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showQrModal: state.showQrModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchHideQrModal: () => dispatch(hideQrModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
